// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-container {
  display: flex;
  flex-direction: column;
}

.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.input-icon {
  position: absolute;
  left: 15px;
  color: #222; /* Icon color */
}

.custom-input {
  width: 100%;
  padding: 15px 15px 15px 15px; /* Adjust padding to accommodate the icon */
  font-size: 16px;
}

.custom-input.with-icon {
  padding-left: 45px; /* Additional left padding when there is an icon */
}

.custom-input::placeholder {
  color: #999; /* Placeholder text color */
  opacity: 1; /* Ensure full opacity */
  font-family: "Lato", sans-serif;
  font-size: 20px;
  font-weight: 100;
}

.input-prefix-text {
  color: #222; /* Text color for prefix */
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
}

/* Hide the calendar icon for date inputs in WebKit browsers */
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
}

/* Hide the calendar icon for date inputs in Internet Explorer */
input[type="date"]::-ms-clear {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/Input.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,WAAW,EAAE,eAAe;AAC9B;;AAEA;EACE,WAAW;EACX,4BAA4B,EAAE,2CAA2C;EACzE,eAAe;AACjB;;AAEA;EACE,kBAAkB,EAAE,kDAAkD;AACxE;;AAEA;EACE,WAAW,EAAE,2BAA2B;EACxC,UAAU,EAAE,wBAAwB;EACpC,+BAA+B;EAC/B,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,WAAW,EAAE,0BAA0B;EACvC,kBAAkB;EAClB,UAAU;EACV,QAAQ;EACR,2BAA2B;AAC7B;;AAEA,8DAA8D;AAC9D;EACE,aAAa;AACf;;AAEA,gEAAgE;AAChE;EACE,aAAa;AACf","sourcesContent":[".input-container {\r\n  display: flex;\r\n  flex-direction: column;\r\n}\r\n\r\n.input-wrapper {\r\n  position: relative;\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n\r\n.input-icon {\r\n  position: absolute;\r\n  left: 15px;\r\n  color: #222; /* Icon color */\r\n}\r\n\r\n.custom-input {\r\n  width: 100%;\r\n  padding: 15px 15px 15px 15px; /* Adjust padding to accommodate the icon */\r\n  font-size: 16px;\r\n}\r\n\r\n.custom-input.with-icon {\r\n  padding-left: 45px; /* Additional left padding when there is an icon */\r\n}\r\n\r\n.custom-input::placeholder {\r\n  color: #999; /* Placeholder text color */\r\n  opacity: 1; /* Ensure full opacity */\r\n  font-family: \"Lato\", sans-serif;\r\n  font-size: 20px;\r\n  font-weight: 100;\r\n}\r\n\r\n.input-prefix-text {\r\n  color: #222; /* Text color for prefix */\r\n  position: absolute;\r\n  left: 16px;\r\n  top: 50%;\r\n  transform: translateY(-50%);\r\n}\r\n\r\n/* Hide the calendar icon for date inputs in WebKit browsers */\r\ninput[type=\"date\"]::-webkit-calendar-picker-indicator {\r\n  display: none;\r\n}\r\n\r\n/* Hide the calendar icon for date inputs in Internet Explorer */\r\ninput[type=\"date\"]::-ms-clear {\r\n  display: none;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
