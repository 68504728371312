import React, { useState } from "react";
import { fontScale, scaleWidth } from "../../utils/responsive";

import BottomNavBar from "../../components/BottomNavBar";
import Spacer from "../../components/Spacer";
import { useNavigate } from "react-router-dom";
import welcomeImage1 from "../../assets/imgs/welcome/welcome01.png";
import welcomeImage2 from "../../assets/imgs/welcome/welcome02.png";
import welcomeImage3 from "../../assets/imgs/welcome/welcome03.png";
import Button from "../../components/Button";

function WelcomeScreen() {
  const navigate = useNavigate();

  const slides = [
    {
      imgUrl: welcomeImage1,
      imgWidth: 320,
      marginTop: 61,
      title: "È arrivato il momento di farti notare da tutti!",
      description:
        "Se possiedi un locale o un'attività, Evento Manager© è lo strumento perfetto per gestire e sponsorizzare i tuoi eventi. Fai brillare i tuoi spazi e attira una folla entusiasta! \n\nBenvenuto su Evento Manager©",
    },
    {
      imgUrl: welcomeImage2,
      imgWidth: 308,
      marginTop: 21,
      title: "Gestisci i tuoi eventi su un unica piattaforma",
      description:
        "Se possiedi un locale o un'attività, Evento Executive© è lo strumento perfetto per gestire e sponsorizzare i tuoi eventi. Fai brillare i tuoi spazi e attira una folla entusiasta!",
    },
    {
      imgUrl: welcomeImage3,
      imgWidth: 316,
      marginTop: 18,
      title: "Crea il tuo Account e inizia a sponsorizzare!",
      description:
        "Se possiedi un locale o un'attività, Evento Executive© è lo strumento perfetto per gestire e sponsorizzare i tuoi eventi. Fai brillare i tuoi spazi e attira una folla entusiasta!",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNextSlide = () => {
    if (currentIndex < slides.length - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    } else {
      navigate("/type");
    }
  };

  const handleBackSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  const nextLabel =
    currentIndex === 0
      ? "SCOPRI COME FUNZIONA"
      : currentIndex < slides.length - 1
      ? "Prosegui"
      : "CREA ACCOUNT";
  const showBackButton = currentIndex > 0;

  return (
    <div
      style={{
        textAlign: "center",
        paddingRight: scaleWidth(27),
        paddingLeft: scaleWidth(35),
        paddingBottom: scaleWidth(55),
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={slides[currentIndex].imgUrl}
          alt="Welcome"
          style={{
            width: scaleWidth(slides[currentIndex].imgWidth),
            height: "auto",
            marginTop: slides[currentIndex].marginTop,
          }}
        />
      </div>
      <Spacer height={49} />
      <h2
        style={{
          fontSize: fontScale(30),
          lineHeight: "120%",
          textAlign: "left",
        }}
      >
        {slides[currentIndex].title}
      </h2>
      <Spacer height={14} />
      <p
        style={{
          fontSize: fontScale(20),
          lineHeight: "135%",
          textAlign: "left",
          whiteSpace: "pre-line",
        }}
      >
        {slides[currentIndex].description}
      </p>

      {/* Redirect to Login */}
      {currentIndex === 0 && (
        <Button
          label="ACCEDI"
          onClick={() => navigate("/login")}
          style={{
            marginTop: scaleWidth(30),
            marginBottom: scaleWidth(30),
          }}
        >
          Vai al login
        </Button>
      )}

      <Spacer height={120} />

      <BottomNavBar
        onBack={handleBackSlide}
        onNext={handleNextSlide}
        showBackButton={showBackButton}
        nextLabel={nextLabel}
        showArrow={currentIndex === 2 ? true : false}
        nextFontSize={currentIndex === 2 ? fontScale(17) : fontScale(18)}
      />
    </div>
  );
}

export default WelcomeScreen;
