import "./EventsScreen.css"; // Import your CSS for styling
import React, { useEffect, useState } from "react";
import authService from "../../../services/authService";
import { deleteItem, filterItems } from "../../../services/apiService";
import { fontScale, scaleWidth } from "../../../utils/responsive";
import { useNavigate } from "react-router-dom";
import Header from "../../../components/Header";
import AddButton from "../../../components/AddButton";
import EventCard from "../../../components/EventCard";

const EventsScreen = () => {
  const [activeTab, setActiveTab] = useState("ongoing");
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchLocalData = async () => {
      try {
        const user = authService.getCurrentUser();

        const host = await filterItems("hosts", {
          attribute: "firebaseUid",
          value: user.uid,
        });

        const eventsResponse = await filterItems("events", {
          attribute: "hostId",
          value: host.data[0].id,
        });

        const ownedEvents = eventsResponse.data;
        setEvents(ownedEvents);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchLocalData();
  }, []);

  const handleDelete = async (eventId) => {
    const confirmDelete = window.confirm(
      "Sei sicuro di voler eliminare questo evento? Questa azione non può essere annullata."
    );

    if (confirmDelete) {
      try {
        await deleteItem("events", eventId);
        setEvents(events.filter((e) => e.id !== eventId));
      } catch (error) {
        console.error("Error deleting event:", error);
      }
    }
  };

  // Filter events based on their date
  const getFilteredEvents = () => {
    const currentDate = new Date();
    if (activeTab === "ongoing") {
      return events.filter((event) => new Date(event.date) >= currentDate);
    } else if (activeTab === "past") {
      return events.filter((event) => new Date(event.date) < currentDate);
    }
    return [];
  };

  const renderContent = () => {
    const filteredEvents = getFilteredEvents();
    return (
      <div
        style={{
          paddingLeft: scaleWidth(20),
          paddingRight: scaleWidth(20),
        }}
      >
        {filteredEvents.length > 0 ? (
          filteredEvents.map((event) => (
            <EventCard key={event.id} event={event} onDelete={handleDelete} />
          ))
        ) : (
          <></>
        )}
        {activeTab === "ongoing" && (
          <AddButton
            text="Crea nuovo evento"
            onClick={() => {
              navigate("/dashboard/event/add", {
                state: { local: { id: "none" } },
              });
            }}
          />
        )}
      </div>
    );
  };

  const renderTabs = () => (
    <div
      className="tabs"
      style={{
        boxShadow: "0px 5px 8px 0px rgba(0,0,0,0.04)",
      }}
    >
      <button
        style={{
          width: "100%",
          fontSize: fontScale(18),
          backgroundColor: "#fff",
          borderBottom:
            activeTab === "ongoing" ? "2px solid #000" : "2px solid #fff",
          fontWeight: activeTab === "ongoing" ? "700" : "normal",
          height: scaleWidth(56),
          fontFamily: "Lato",
          color: activeTab === "ongoing" ? "#222" : "#555",
        }}
        onClick={() => setActiveTab("ongoing")}
      >
        Eventi in corso
      </button>
      <button
        style={{
          width: "100%",
          fontSize: fontScale(18),
          backgroundColor: "#fff",
          borderBottom:
            activeTab === "past" ? "2px solid #000" : "2px solid #fff",
          height: scaleWidth(56),
          fontWeight: activeTab === "past" ? "700" : "normal",
          fontFamily: "Lato",
          color: activeTab === "past" ? "#222" : "#555",
        }}
        onClick={() => setActiveTab("past")}
      >
        Eventi conclusi
      </button>
    </div>
  );

  return (
    <div className="events-screen">
      <Header title="Elenco eventi" type="secondary" />
      {renderTabs()}
      <div className="tab-content">{renderContent()}</div>
    </div>
  );
};

export default EventsScreen;
