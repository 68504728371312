import "./Input.css"; // Import the external CSS file for styling

import React, { useState } from "react";
import { fontScale, scaleWidth } from "../utils/responsive";

import eyeOpen from "../assets/icons/eye-open.png";
import eyeClosed from "../assets/icons/eye-closed.png";

import Label from "./Label";
import PropTypes from "prop-types";

const Input = ({
  label,
  placeholder,
  icon,
  prefixText, // New prop for prefix text
  type = "text", // Default input type is text
  readonly = false, // New readonly prop
  ...props
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <div className="input-container">
      {label && <Label text={label} />}
      <div className="input-wrapper" style={{ position: "relative" }}>
        {prefixText && (
          <span
            className="input-prefix-text"
            style={{
              position: "absolute",
              left: scaleWidth(13),
              top: "50%",
              transform: "translateY(-50%)",
              fontSize: fontScale(20),
              color: "#000",
              paddingRight: scaleWidth(10), // Add spacing after prefix text
              borderRight: "1px solid #cecece",
            }}
          >
            {prefixText}
          </span>
        )}
        {typeof icon === "string" ? (
          <img
            src={icon}
            alt="Icon"
            className="input-icon"
            style={{
              position: "absolute",
              left: prefixText ? scaleWidth(70) : scaleWidth(16), // Adjust position if prefixText is present
              top: "50%",
              transform: "translateY(-50%)",
              width: scaleWidth(24),
              height: scaleWidth(24),
              borderRight: "1px solid #D0D0D0",
              paddingRight: scaleWidth(12),
            }}
          />
        ) : (
          icon && (
            <span
              className="input-icon"
              style={{
                position: "absolute",
                left: prefixText ? scaleWidth(70) : scaleWidth(16), // Adjust position if prefixText is present
                top: "50%",
                transform: "translateY(-50%)",
                color: "#222", // Icon color
              }}
            >
              {icon}
            </span>
          )
        )}
        <input
          className={`custom-input ${icon || prefixText ? "with-icon" : ""}`}
          style={{
            fontSize: fontScale(20),
            borderColor: readonly ? "#f9f9f9" : "#cecece",
            borderWidth: 0.5,
            borderRadius: 10,
            color: "#000", // Input text color
            paddingLeft: icon || prefixText ? scaleWidth(64) : scaleWidth(15), // Adjust padding for left icon or prefix text
            backgroundColor: readonly ? "#f9f9f9" : "#fff", // Change background if readonly
            fontFamily: "Lato",
          }}
          type={isPasswordVisible ? "text" : type}
          placeholder={placeholder}
          readOnly={readonly} // Apply readonly attribute
          {...props}
        />
        {type === "password" &&
          !readonly && ( // Hide password toggle if readonly
            <span
              className="password-toggle-icon"
              onClick={togglePasswordVisibility}
              style={{
                position: "absolute",
                right: scaleWidth(15),
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
                color: "#000",
              }}
            >
              {isPasswordVisible ? (
                <img
                  src={eyeClosed}
                  style={{ width: scaleWidth(24) }}
                  alt="Nascondi"
                />
              ) : (
                <img
                  src={eyeOpen}
                  style={{ width: scaleWidth(24) }}
                  alt="Mostra"
                />
              )}
            </span>
          )}
      </div>
    </div>
  );
};

Input.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  icon: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string, // Accept a string (image URL or path) as an icon
  ]),
  prefixText: PropTypes.string, // New prop for prefix text
  type: PropTypes.string, // Include the type prop
  readonly: PropTypes.bool, // New readonly prop
};

export default Input;
