import "./TicketScreen.css"; // Import your CSS for styling

import { fontScale, scaleWidth } from "../../../utils/responsive";

import Header from "../../../components/Header";
import React from "react";
import Spacer from "../../../components/Spacer";
import wipImg from "../../../assets/imgs/wip.png";

const TicketScreen = () => {
  return (
    <div className="notifications-screen">
      <Header type={"secondary"} title={"Gestione biglietti"} />
      <div className="notifications-list">
        <Spacer height={20} />

        <img
          src={wipImg}
          alt="Work in progress"
          style={{ width: scaleWidth(334), height: "auto" }}
        />

        <div>
          <h2>Ci stiamo lavorando...</h2>
          <Spacer height={10} />
          <p style={{ fontSize: fontScale(19) }}>
            I dati che ci fornirai saranno utilizzati esclusivamente per la tua
            registrazione all’interno della piattaforma.
            <br /> Nessun dato sensibile verrà condiviso.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TicketScreen;
