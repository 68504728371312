import { fontScale, scaleWidth } from "../../../utils/responsive";
import { useLocation, useNavigate } from "react-router-dom";

import Button from "../../../components/Button";
import React from "react";
import Spacer from "../../../components/Spacer";
import localImage from "../../../assets/imgs/host-success.png";

const AddedLocalScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const localName = location.state?.localName || "Il tuo locale";
  const local = location.state?.local || undefined;

  return (
    <div
      style={{
        paddingRight: scaleWidth(40),
        paddingLeft: scaleWidth(40),
        paddingTop: scaleWidth(60),
      }}
    >
      <img style={{ width: scaleWidth(303) }} src={localImage} alt="Locale" />

      <Spacer height={20} />
      <h1
        style={{
          color: "#222",
          fontSize: scaleWidth(28),
          textAlign: "left",
        }}
      >
        Hai aggiunto l'Host: <br />{" "}
        <span style={{ color: "#ea802f" }}>{localName}</span>
      </h1>
      <Spacer height={20} />
      <p
        style={{
          fontSize: fontScale(20),
          color: "#222",
        }}
      >
        Ora è il momento di sponsorizzare tutti gli eventi del tuo Host. Crea un
        evento, pubblicalo e fatti trovare da tutti i tuoi futuri clienti!
      </p>
      <Spacer height={100} />
      <div style={{ textAlign: "center" }}>
        <Button
          type={"success"}
          onClick={() => {
            navigate("/dashboard/locals");
            setTimeout(() => {
              navigate("/dashboard/event/add", {
                state: { local },
              });
            }, 10);
          }}
          style={{ width: "100%", height: scaleWidth(58), paddingTop: 13 }}
        >
          CREA UN EVENTO
        </Button>
        <Spacer height={10} />
        <Button type={"back"} onClick={() => navigate("/dashboard/locals")}>
          Visualizza elenco locali
        </Button>
      </div>

      <Spacer height={20} />

      <Spacer height={100} />
    </div>
  );
};

export default AddedLocalScreen;
