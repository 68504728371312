import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../../components/Header";
import { createItem } from "../../../services/apiService";

const EventPreview = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { event, isNew } = location.state || {}; // Retrieve event and isNew from state

  const onSave = async () => {
    try {
      if (event.localId === "none") {
        delete event.localId;
      }
      const response = await createItem("events", event); // POST API for creating a new event
      console.log("Event created successfully:", response.data);

      // Navigate back and pass the new event data for potential editing
      navigate("/dashboard");
    } catch (error) {
      console.error("Error saving event:", error);
    }
  };

  const onEdit = () => {
    console.log("Edit event");
    navigate("/dashboard/event/edit", { state: { eventData: event } }); // Pass the event data back for editing
  };

  const renderAttributes = () => {
    if (!event) {
      return <p>No event data available</p>;
    }

    return Object.entries(event).map(([key, value]) => {
      // Handle objects by stringifying them
      const displayValue =
        value && typeof value === "object" && !Array.isArray(value)
          ? JSON.stringify(value, null, 2) // Pretty-print objects
          : value?.toString();

      return (
        <div
          key={key}
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "8px 16px",
            backgroundColor: value ? "#f9f9f9" : "#ffe6e6",
            borderBottom: "1px solid #ddd",
          }}
        >
          <span style={{ fontWeight: "bold", textTransform: "capitalize" }}>
            {key}
          </span>
          <span
            style={{
              color: value ? "#333" : "#d9534f",
              whiteSpace: "pre-wrap", // Allow multiline for stringified objects
              textAlign: "right",
              maxWidth: "60%", // Limit width to prevent layout issues
              wordBreak: "break-word", // Break long words
            }}
          >
            {displayValue || "-"}
          </span>
        </div>
      );
    });
  };

  const handleBack = () => {
    if (isNew) {
      console.log("Navigate back to dashboard", event.description);
      navigate("/dashboard/event/add/more", { state: { eventData: event } }); // Pass the event data back for editing
    } else {
      navigate(-1);
    }
  };

  return (
    <div>
      <Header
        title={isNew ? "Anteprima" : event.name}
        type="tertiary"
        saveText={isNew ? "Pubblica" : "Modifica"}
        onSave={isNew ? onSave : onEdit}
        hasBackButton={true}
        onBack={handleBack}
      />
      <div
        style={{
          marginTop: "16px",
          border: "1px solid #ddd",
          borderRadius: "8px",
          overflow: "hidden",
        }}
      >
        {renderAttributes()}
      </div>
    </div>
  );
};

export default EventPreview;
