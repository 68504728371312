import React, { useState } from "react";

import Button from "../../components/Button";
import Input from "../../components/Input";
import Spacer from "../../components/Spacer";
import authService from "../../services/authService"; // Import the authService
import logoImage from "../../assets/imgs/logo.png";
import { scaleWidth } from "../../utils/responsive";
import { useNavigate } from "react-router-dom";

const LoginScreen = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const handleLogin = async () => {
    try {
      // Use authService to sign in with email and password
      const { user } = await authService.signInWithEmail(email, password);

      // After successful login, you can navigate to the dashboard or any other page
      console.log("User logged in:", user);
      navigate("/dashboard/locals");
    } catch (error) {
      // Handle authentication errors
      console.error("Login error:", error);
      setError("Email o password non corretti. Riprova.");
    }
  };

  return (
    <div
      style={{
        paddingRight: scaleWidth(32),
        paddingLeft: scaleWidth(32),
      }}
    >
      <Spacer height={20} />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img style={{ width: scaleWidth(80) }} src={logoImage} alt="Logo" />
      </div>
      <Spacer height={10} />
      <h1
        style={{
          color: "#222",
          textAlign: "center",
          fontSize: scaleWidth(30),
          fontWeight: "900",
        }}
      >
        Evento Manager
      </h1>
      <Spacer height={8} />
      <p
        style={{
          color: "#222",
          textAlign: "center",
          fontSize: scaleWidth(20),
        }}
      >
        Gestisci e sponsorizza i tuoi eventi grazie ad un unica piattaforma
      </p>
      <Spacer height={50} />
      <Input
        label={"Indirizzo email"}
        placeholder="Inserisci indirizzo email"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Spacer height={28} />
      <Input
        label={"Password"}
        placeholder="Inserisci Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <Spacer height={12} />

      {error && (
        <p
          style={{
            color: "red",
            textAlign: "center",
            fontSize: scaleWidth(15),
          }}
        >
          {error}
        </p>
      )}

      <Spacer height={50} />
      <Button
        type="primary"
        onClick={handleLogin}
        style={{
          width: "100%",
          height: scaleWidth(56),
        }}
      >
        ACCEDI
      </Button>

      <Spacer height={10} />
      <Button
        type="info"
        onClick={() => {
          console.log("Password dimenticata");
          // Here you can navigate to a "Forgot Password" screen or handle password reset
        }}
        style={{
          width: "100%",
          height: scaleWidth(56),
          backgroundColor: "transparent",
          color: "#d1732b",
          fontSize: scaleWidth(18),
          fontWeight: "400",
        }}
      >
        Non ricordi la password?
      </Button>

      <Spacer height={20} />

      <p
        style={{
          color: "#222",
          textAlign: "center",
          fontSize: scaleWidth(13),
          width: scaleWidth(328),
        }}
      >
        Effettuando l’accesso, accetti i nostri{" "}
        <u style={{ color: "#d1732b" }}>Termini di Servizio</u> e confermi di
        aver letto la nostra{" "}
        <u style={{ color: "#d1732b" }}>Informativa sulla Privacy</u> per sapere
        in che modo raccogliamo, trattiamo e condividiamo i tuoi dati e la
        nostra
        <u style={{ color: "#d1732b" }}> Informativa sui Cookie</u> per sapere
        in che modo utilizziamo i cookie.
      </p>
    </div>
  );
};

export default LoginScreen;
