import Button from "./Button";
import React from "react";
import Spacer from "./Spacer";
import rightArrow from "../assets/icons/arrow-right.png";
import { scaleWidth } from "../utils/responsive";
import { useNavigate } from "react-router-dom";

const BottomNavBar = ({
  onBack,
  onNext,
  showBackButton = true,
  backLabel = "Indietro",
  nextLabel = "Prosegui",
  showArrow = false,
  backFontSize,
  nextFontSize,
  disableNext = false, // New prop to control 'disabled' state of the next button
}) => {
  const navigate = useNavigate();

  // Default to navigate(-1) if no onBack is provided
  const handleBack = () => {
    if (onBack) {
      onBack();
    } else {
      navigate(-1); // Go back in history
    }
  };

  return (
    <div
      style={{
        display: "flex",
        position: "fixed",
        height: scaleWidth(55),
        paddingLeft: scaleWidth(30),
        paddingTop: scaleWidth(13),
        paddingBottom: scaleWidth(13),
        paddingRight: scaleWidth(25),
        left: 0,
        right: 0,
        bottom: 0,
        justifyContent: "space-between",
        backgroundColor: "#fff",
        boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.05)",
      }}
    >
      {showBackButton ? (
        <>
          <Button type="back" onClick={handleBack} fontSize={backFontSize}>
            {backLabel}
          </Button>
          <Spacer width={30} />
          <Button
            type="primary"
            onClick={onNext}
            fontSize={nextFontSize}
            disabled={disableNext}
          >
            {nextLabel}
            {showArrow && (
              <img
                src={rightArrow}
                alt="Arrow"
                style={{
                  width: scaleWidth(7),
                  height: scaleWidth(13),
                  marginLeft: scaleWidth(11),
                }}
              />
            )}
          </Button>
        </>
      ) : (
        // If there's no back button, make the next button full width
        <Button
          type="primary"
          onClick={onNext}
          fontSize={nextFontSize}
          style={{
            width: scaleWidth(328),
          }}
          disabled={disableNext}
        >
          {nextLabel}
          {showArrow && (
            <img
              src={rightArrow}
              alt="Arrow"
              style={{
                width: scaleWidth(7),
                height: scaleWidth(13),
                marginLeft: scaleWidth(11),
              }}
            />
          )}
        </Button>
      )}
    </div>
  );
};

export default BottomNavBar;
