import React, { useState } from "react";
import { fontScale, scaleWidth } from "../../../utils/responsive";
import { useLocation, useNavigate } from "react-router-dom";

import { updateItem } from "../../../services/apiService";
import Header from "../../../components/Header";
import Spacer from "../../../components/Spacer";
import AddEventScreen from "./AddEvent";
import AddEventMoreScreen from "./AddEventMore";

const EditEventScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const eventData = location.state?.eventData || {};

  // console.log("Event data:", eventData);

  const [formData, setFormData] = useState({
    ...eventData,
    id: eventData.id || "",
    name: eventData.name || "",
    localId: eventData.local._id || "",
    address: eventData.address || "",
    date: eventData.date ? eventData.date.split("T")[0] : "",
    endDate: eventData.endDate ? eventData.endDate.split("T")[0] : "",
    startTime: eventData.startTime || "",
    endTime: eventData.endTime || "",
    price: eventData.price || "",
    parkingPrice: eventData.parkingPrice || 0,
    category: eventData.category || "",
    categories: eventData.categories || [],
    tags: eventData.tags || [],
    description: eventData.description || "",
    imgUrl: eventData.imgUrl || "",
    parking: eventData.parking || [],
    isParkingSecure: eventData.isParkingSecure || false,
    parkingAddress: eventData.parkingAddress || "",
  });

  // console.log("Form data:", formData);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({ ...formData, [name]: type === "checkbox" ? checked : value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedEventData = {
      ...formData,
      endDate: formData.hasEndDate ? formData.endDate : null,
      price: parseFloat(formData.price),
      parkingPrice: parseFloat(formData.parkingPrice),
    };

    console.log("Updating event:", updatedEventData);

    updateItem(`events/`, formData.id, updatedEventData)
      .then((response) => {
        console.log("Event updated successfully:", response.data);
        navigate(-2);
      })
      .catch((error) => {
        console.error("Error updating event:", error);
      });
  };

  const [activeTab, setActiveTab] = useState("Informazioni");

  const renderTabs = () => {
    return (
      <div
        style={{
          width: scaleWidth(342),
          height: scaleWidth(66),
          backgroundColor: "#fafafa",
          borderRadius: scaleWidth(100),
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          paddingLeft: scaleWidth(10),
          paddingRight: scaleWidth(10),
          justifyContent: "space-between",
        }}
      >
        {["Informazioni", "Categorie e Tag"].map((tab) => (
          <div
            key={tab}
            onClick={() => setActiveTab(tab)}
            style={{
              height: scaleWidth(47),
              width: scaleWidth(171),
              borderRadius: scaleWidth(100),
              justifyContent: "center",
              backgroundColor: activeTab === tab ? "#fff" : "transparent",
              alignItems: "center",
              display: "flex",
              boxShadow:
                activeTab === tab ? "0px 2px 4px rgba(0, 0, 0, 0.05)" : "none",
              cursor: "pointer",
            }}
          >
            <p
              style={{
                fontSize: fontScale(19),
                fontWeight: activeTab === tab ? "bold" : "normal",
                color: activeTab === tab ? "#000" : "#555",
              }}
            >
              {tab}
            </p>
          </div>
        ))}
      </div>
    );
  };

  const renderContent = () => {
    switch (activeTab) {
      case "Informazioni":
        return (
          <AddEventScreen
            mode="edit"
            editData={formData}
            setEditedFormData={setFormData}
          />
        );
      case "Categorie e Tag":
        return (
          <AddEventMoreScreen
            mode="edit"
            editData={formData}
            setEditedFormData={setFormData}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <Header
        type="tertiary"
        title="Modifica evento"
        hasBackButton={true}
        saveText={"Salva"}
        onSave={handleSubmit}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Spacer height={12} />
        {renderTabs()}
        <Spacer height={20} />
        <div
          style={{
            maxWidth: "100%",
          }}
        >
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default EditEventScreen;
