import "./App.css";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import React, { useEffect, useState } from "react";
import AuthNavigator from "./navigators/AuthNavigator";
import DashboardNavigator from "./navigators/DashboardNavigator";
import authService from "./services/authService";
import { filterItems } from "./services/apiService";
import LoadingScreen from "./components/LoadingScreen";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    // Listen to authentication state changes
    const unsubscribe = authService.onAuthStateChanged(async (user) => {
      if (user) {
        setIsAuthenticated(true);
        try {
          // Fetch the user role from the database
          const host = await filterItems("hosts", {
            attribute: "firebaseUid",
            value: user.uid,
          });

          if (host.data.length > 0) {
            setUserRole(host.data[0].role);
          } else {
            // If the role isn't found, try to get it from localStorage
            console.error("User role not found in the database.");
            const storedRole = localStorage.getItem("selectedType");
            if (storedRole) {
              setUserRole(storedRole);
              // Optionally, you can save the role to the database here
            } else {
              setUserRole(null);
            }
          }
        } catch (error) {
          console.error("Error fetching user role:", error);
          // Try to get role from localStorage
          const storedRole = localStorage.getItem("selectedType");
          if (storedRole) {
            setUserRole(storedRole);
            // Optionally, you can save the role to the database here
          } else {
            setUserRole(null);
          }
        }
      } else {
        setIsAuthenticated(false);
      }
      setLoading(false);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  if (loading || (isAuthenticated && userRole === null)) {
    return <LoadingScreen />; // Show loading until the role is fetched
  }

  const defaultDashboardRoute =
    userRole !== "owner" ? "/dashboard/events" : "/dashboard/locals";

  return (
    <div className="main">
      <Router>
        <Routes>
          {!isAuthenticated ? (
            // If not authenticated, show AuthNavigator
            <Route path="/*" element={<AuthNavigator />} />
          ) : (
            // If authenticated, determine the default route based on the user's role
            <>
              <Route path="/dashboard/*" element={<DashboardNavigator />} />
              <Route
                path="/dashboard"
                element={<Navigate to={defaultDashboardRoute} replace />}
              />
              <Route
                path="*"
                element={<Navigate to={defaultDashboardRoute} replace />}
              />
            </>
          )}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
