import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import BottomNavBar from "../../components/BottomNavBar";
import Header from "../../components/Header";
import Spacer from "../../components/Spacer";
import TypeBox from "../../components/Auth/TypeBox";
import colors from "../../assets/styles/colors";
import { scaleWidth } from "../../utils/responsive";

function TypeScreen() {
  const navigate = useNavigate();
  const [selectedType, setSelectedType] = useState(null);

  const handleSelection = (type) => {
    setSelectedType(type);
  };

  const onNext = () => {
    // Save the selectedType to localStorage
    localStorage.setItem("selectedType", selectedType);
    navigate("/details", { state: { accountType: selectedType } });
  };

  return (
    <div>
      <Header title="CREA IL TUO ACCOUNT 1-3" />
      <Spacer height={25} />
      <h1
        style={{
          color: colors.black,
          textAlign: "left",
          fontSize: scaleWidth(30),
          lineHeight: "118%",
          fontWeight: 900,
          marginLeft: scaleWidth(28),
          marginRight: scaleWidth(28),
        }}
      >
        Quale profilo ritieni più adatto per la tua attuale professione?
      </h1>
      <Spacer height={47} />

      <TypeBox
        type="owner"
        selectedType={selectedType}
        onClick={() => handleSelection("owner")}
        selected={selectedType === "owner"}
        title="Proprietario di un locale"
        description="Potrai sponsorizzare i tuoi locali e tutti gli eventi ad essi collegati collaborando anche con organizzatori o enti pubblici."
      />
      <Spacer height={33} />
      <TypeBox
        type="organizator"
        selectedType={selectedType}
        onClick={() => handleSelection("organizator")}
        selected={selectedType === "organizator"}
        title={"Organizzatore di eventi \nper il pubblico e/o enti privati"}
        description="Potrai sponsorizzare tutti i tuoi eventi (musicali, culturali ecc.) collaborando con locali e altri organizzatori nella tua città."
      />

      <BottomNavBar
        onNext={onNext}
        showBackButton={true}
        nextLabel={"Continua"}
        showArrow={true}
        disableNext={!selectedType}
      />
    </div>
  );
}

export default TypeScreen;
