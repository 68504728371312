const categories = {
  CULTURA: [
    "Animazione",
    "Arte di strada",
    "Cinema",
    "Circensi",
    "Cucina",
    "Design",
    "Disegno",
    "Documentari",
    "Fumetti",
    "Grafica",
    "Illustrazione",
    "Installazioni",
    "Manga",
    "Moda & Fashion",
    "Musica",
    "Narrativa",
    "Opera",
    "Performance",
    "Pittura",
    "Poesia",
    "Saggistica",
    "Scultura",
    "Teatro",
    "Televisione",
    "Videogiochi",
  ],
  SPORT: [
    "Arti Marziali",
    "Alpinismo",
    "Arrampicata",
    "Atletica leggera",
    "Badminton",
    "Baseball",
    "Basket",
    "Biliardo",
    "Bocce",
    "Bodybuilding",
    "Calcio",
    "Ciclismo",
    "Danza",
    "Equitazione",
    "E-Sports",
    "Football",
    "Freccette",
    "Giochi da tavolo",
    "Giochi di carte",
    "Golf",
    "Ginnastica artistica",
    "Hockey",
    "Motori",
    "Multidisciplinare",
    "Padel",
    "Pallamano",
    "Pallavolo",
    "Rugby",
    "Scacchi",
    "Scherma",
    "Sport acquatici",
    "Sport invernali",
    "Tennis Tavolo",
    "Tiro",
    "Tiro con l'arco",
  ],
  MUSICA: [
    "Afro",
    "Blues",
    "Classica",
    "Country",
    "Disco Music",
    "EDM",
    "Electro Swing",
    "Electronic",
    "Folk",
    "Funk",
    "Gospel",
    "Hip Hop",
    "House",
    "Italiana",
    "Indie",
    "Jazz",
    "Karaoke",
    "Latino Americano",
    "Metal",
    "Pop",
    "Punk",
    "Rap",
    "Reggae",
    "Reggaeton",
    "Rock",
    "Techno",
    "Tekno",
    "Trap",
  ],
  FIERE: [
    "Abbigliamento",
    "Agricoltura",
    "Alimentari",
    "Arredi/forniture",
    "Automotive",
    "Cosmesi",
    "Edilizia",
    "Erboristica",
    "Gaming",
    "Giardinaggio",
    "Ho.Re.Ca",
    "Igiene/Sanità",
    "Industria",
    "Logistica",
    "Packaging",
    "Prod. PET",
    "Sicurezza",
    "Sport",
    "Tecnologia",
    "Turismo",
  ],
  FESTIVITÀ: ["Anniversari", "Cerimonie", "Messe", "Patroni", "Sagre"],
  RISTORO: [
    "Aperitivi",
    "Apericena",
    "Barbecue",
    "Birra",
    "Cocktails",
    "Colazione dolce",
    "Colazione salata",
    "Cucina asiatica",
    "Cucina indiana",
    "Cucina italiana",
    "Cucina messicana",
    "Cucina vegetariana",
    "Cucina vegana",
    "Degustazione",
    "Dolci e Dessert",
    "Gourmet",
    "Gluten Free",
    "Pizza",
    "Street Food",
    "Spirits",
    "Sushi",
    "Vini e bollicine",
  ],
};

export default categories;
