import React, { useEffect, useState } from "react";
import { fontScale, scaleWidth } from "../../utils/responsive";
import { useLocation, useNavigate } from "react-router-dom";

import BottomNavBar from "../../components/BottomNavBar";
import Header from "../../components/Header";
import Input from "../../components/Input";
import Spacer from "../../components/Spacer";
import colors from "../../assets/styles/colors";

// Pre-compiled form values for testing
const preCompiledForm = {
  companyName: "Eventi S.r.l.",
  vatNumber: "12345678901",
  adminName: "Mario Rossi",
  phoneNumber: "1234567890",
  pecAddress: "mario.rossi@pec.it",
};

// Toggle to enable/disable pre-compiled form
const usePreCompiledForm = false; // Set this to true to enable pre-compiled form

const SignupDetailsScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { accountType } = location.state || {};

  // Initialize form values
  const [formValues, setFormValues] = useState(
    usePreCompiledForm
      ? preCompiledForm
      : {
          companyName: "",
          vatNumber: "",
          adminName: "",
          phoneNumber: "",
          pecAddress: "",
          displayName: "",
        }
  );

  const [formErrors, setFormErrors] = useState({
    vatNumber: "",
    adminName: "",
    phoneNumber: "",
    pecAddress: "",
    displayName: "",
  });

  const [touched, setTouched] = useState({
    vatNumber: false,
    adminName: false,
    phoneNumber: false,
    pecAddress: false,
  });

  const [isFormValid, setIsFormValid] = useState(false);

  // Validation functions
  const validateVAT = (vatNumber) => {
    const vatRegex = /^\d{11}$/;
    return vatRegex.test(vatNumber);
  };

  const validateName = (name) => {
    const nameRegex = /^[a-zA-Z\s]+$/;
    return nameRegex.test(name);
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phoneNumber);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email) || email === "";
  };

  useEffect(() => {
    const errors = {};

    if (!validateVAT(formValues.vatNumber)) {
      errors.vatNumber = "P.IVA deve essere un numero valido di 11 cifre.";
    }

    if (!validateName(formValues.adminName)) {
      errors.adminName = "Il nome può contenere solo lettere.";
    }

    if (!validatePhoneNumber(formValues.phoneNumber)) {
      errors.phoneNumber = "Il numero di telefono deve essere di 10 cifre.";
    }

    if (!validateEmail(formValues.pecAddress)) {
      errors.pecAddress =
        "L'indirizzo PEC deve essere un indirizzo email valido.";
    }

    setFormErrors(errors);

    const isValid =
      formValues.companyName.trim() !== "" && !Object.keys(errors).length;

    setIsFormValid(isValid);
  }, [formValues]);

  const handleInputChange = (field, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const handleBlur = (field) => {
    setTouched((prevTouched) => ({
      ...prevTouched,
      [field]: true,
    }));
  };

  const handleSubmit = () => {
    if (isFormValid) {
      navigate("/signup", {
        state: {
          accountType,
          company: formValues.companyName,
          displayName: formValues.displayName,
          vat: formValues.vatNumber,
          admin: formValues.adminName,
          phone: formValues.phoneNumber,
          pec: formValues.pecAddress,
        },
      });
    }
  };

  return (
    <div>
      <Header title="CREA IL TUO ACCOUNT 2-3" />
      <Spacer height={20} />
      <div
        style={{
          paddingLeft: scaleWidth(28),
          paddingRight: scaleWidth(28),
        }}
      >
        <h1
          style={{
            color: colors.black,
            textAlign: "left",
            fontSize: scaleWidth(30),
            lineHeight: "118%",
            fontWeight: 900,
          }}
        >
          Inserisci le informazioni necessarie e conferma la tua identità reale
        </h1>
        <Spacer height={10} />
        <p style={{ color: colors.black, fontSize: fontScale(20) }}>
          Per tutelare i nostri utenti all’interno della piattaforma sarà
          necessario inserire informazioni personali.*
        </p>
        <Spacer height={40} />
        {accountType !== "owner" && (
          <>
            <Input
              label={"Nome del profilo"}
              placeholder='"PartyEvents"'
              type="text"
              value={formValues.displayName}
              onChange={(e) => handleInputChange("displayName", e.target.value)}
            />
            <Spacer height={30} />
          </>
        )}
        <Input
          label={"Ragione Sociale*"}
          placeholder='Es. "Eventi S.r.l."'
          type="text"
          value={formValues.companyName}
          onChange={(e) => handleInputChange("companyName", e.target.value)}
        />
        <Spacer height={30} />
        <div style={{ width: scaleWidth(170) }}>
          <Input
            label={"Codice P.IVA*"}
            placeholder="_ _ _ _ _ _ _ _ _ _"
            type="text"
            value={formValues.vatNumber}
            onChange={(e) => handleInputChange("vatNumber", e.target.value)}
            onBlur={() => handleBlur("vatNumber")}
          />
        </div>
        {touched.vatNumber && formErrors.vatNumber && (
          <p style={{ color: "red", fontSize: fontScale(14) }}>
            {formErrors.vatNumber}
          </p>
        )}

        <Spacer height={30} />
        <Input
          label={"Intestatario P.IVA*"}
          placeholder="Inserisci nome e cognome"
          type="text"
          value={formValues.adminName}
          onChange={(e) => handleInputChange("adminName", e.target.value)}
          onBlur={() => handleBlur("adminName")}
        />
        {touched.adminName && formErrors.adminName && (
          <p style={{ color: "red", fontSize: fontScale(14) }}>
            {formErrors.adminName}
          </p>
        )}
        <Spacer height={30} />
        <Input
          label={"Numero di telefono*"}
          placeholder="Inserisci il numero"
          type="tel"
          value={formValues.phoneNumber}
          prefixText="+39"
          onChange={(e) => handleInputChange("phoneNumber", e.target.value)}
          onBlur={() => handleBlur("phoneNumber")}
        />
        {touched.phoneNumber && formErrors.phoneNumber && (
          <p style={{ color: "red", fontSize: fontScale(14) }}>
            {formErrors.phoneNumber}
          </p>
        )}
        <Spacer height={30} />
        <Input
          label={"Indirizzo PEC"}
          placeholder="Inserisci indirizzo PEC"
          type="text"
          value={formValues.pecAddress}
          onChange={(e) => handleInputChange("pecAddress", e.target.value)}
          onBlur={() => handleBlur("pecAddress")}
        />
        {touched.pecAddress && formErrors.pecAddress && (
          <p style={{ color: "red", fontSize: fontScale(14) }}>
            {formErrors.pecAddress}
          </p>
        )}
        <Spacer height={150} />
        <BottomNavBar
          onNext={handleSubmit}
          showBackButton={true}
          nextLabel={"Continua"}
          showArrow={true}
          disableNext={!isFormValid}
        />
      </div>
    </div>
  );
};

export default SignupDetailsScreen;
