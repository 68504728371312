import React, { useState } from "react";
import PropTypes from "prop-types";
import { fontScale, scaleWidth } from "../utils/responsive";
import arrowDown from "../assets/icons/arrow-simple-down.png";

const Select = ({ name, value, options, onChange, placeholder }) => {
  const [isOpen, setIsOpen] = useState(false); // Controls modal visibility
  const [selectedValue, setSelectedValue] = useState(value || "");
  const [selectedLabel, setSelectedLabel] = useState(
    options.find((option) => option.value === value)?.label || ""
  );

  const handleSelectOption = (option) => {
    setSelectedValue(option.value);
    setSelectedLabel(option.label);
    onChange({ target: { name, value: option.value } }); // Mimic the event for onChange

    setIsOpen(false); // Close the modal
  };

  return (
    <div
      style={{ position: "relative", width: "100%" }}
      ocClick={() => setIsOpen(false)}
    >
      {/* Input Field */}
      <div
        style={{
          color: "#222",
          backgroundColor: "#fff",
          fontSize: "20px",
          borderRadius: 10,
          padding: "15px",
          width: scaleWidth(247),
          fontFamily: "Lato",
          fontWeight: "500",
          cursor: "pointer",
          justifyContent: "space-between",
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
        }}
        onClick={() => setIsOpen(true)} // Open modal on click
      >
        {selectedLabel || placeholder}

        <img
          src={arrowDown}
          style={{
            width: scaleWidth(17),
            height: scaleWidth(10),
          }}
          alt="Arrow Down"
        />
      </div>

      {/* Modal for options */}
      {isOpen && (
        <div style={styles.modalBackdrop}>
          <div style={styles.modalContent}>
            {options.map((option) => (
              <div
                key={option.value}
                style={styles.option}
                onClick={() => handleSelectOption(option)}
              >
                {option.label}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

// Component styles
const styles = {
  modalBackdrop: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
  },
  modalContent: {
    backgroundColor: "#fff",
    borderRadius: 10,
    paddingTop: scaleWidth(3),
    paddingBottom: scaleWidth(24),
    width: scaleWidth(277),
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
  },
  option: {
    paddingLeft: scaleWidth(27),
    paddingTop: scaleWidth(15),
    paddingBottom: scaleWidth(15),

    cursor: "pointer",
    fontSize: fontScale(19),
    fontFamily: "Lato",
    fontWeight: 500,
    color: "#222",
    borderBottom: "1px solid #eee",
  },
  closeButton: {
    marginTop: "15px",
    padding: "10px",
    fontSize: "16px",
    color: "#fff",
    backgroundColor: "#ea802f",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    width: "100%",
  },
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

Select.defaultProps = {
  placeholder: "Seleziona tipologia",
};

export default Select;
