import React, { useState } from "react";
import { scaleWidth } from "../utils/responsive";
import Spacer from "./Spacer";
import dotsIcon from "../assets/icons/dots-vertical.png";
import colors from "../assets/styles/colors";
import { useNavigate } from "react-router-dom";

const EventCard = ({ event, onDelete, hostName }) => {
  const navigate = useNavigate();

  const [showOptions, setShowOptions] = useState(false);

  const toggleOptions = (e) => {
    e.stopPropagation(); // Prevent card click from firing
    setShowOptions((prev) => !prev);
  };

  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);
    const day = dateObj.toLocaleString("it-IT", { weekday: "short" });
    const date = dateObj.getDate().toString().padStart(2, "0");
    const month = dateObj.toLocaleString("it-IT", { month: "short" });

    const hours = dateObj.getHours().toString().padStart(2, "0");
    const minutes = dateObj.getMinutes().toString().padStart(2, "0");

    const capitalizeFirstLetter = (string) =>
      string.charAt(0).toUpperCase() + string.slice(1);

    return `${capitalizeFirstLetter(day)}, ${date} ${capitalizeFirstLetter(
      month
    )} · ${hours}:${minutes}`;
  };

  const formatAddress = (address) => {
    const parts = address.split(",");
    if (parts.length < 2) {
      return address;
    }
    const trimmedParts = parts.map((part) => part.trim());
    const cityProvincePart = trimmedParts[trimmedParts.length - 2];
    let cityProvinceWords = cityProvincePart.split(" ");

    while (cityProvinceWords.length && !isNaN(Number(cityProvinceWords[0]))) {
      cityProvinceWords.shift();
    }

    if (cityProvinceWords.length < 2) {
      return address;
    }

    const provinceCode = cityProvinceWords[cityProvinceWords.length - 1];
    const provinceCodePattern = /^[A-Z]{2}$/;
    if (!provinceCodePattern.test(provinceCode)) {
      return address;
    }

    const cityName = cityProvinceWords.slice(0, -1).join(" ");
    if (!cityName) {
      return address;
    }

    return `${cityName} (${provinceCode})`;
  };

  const onCardPress = () => {
    if (showOptions) {
      setShowOptions(false);
      return;
    } else {
      console.log("Card pressed");
      navigate(`/dashboard/event/preview`, {
        state: {
          event: event,
          isNew: false,
        },
      });
    }
  };

  return (
    <div
      onClick={onCardPress} // Close options box when clicking the card
      style={{
        width: scaleWidth(332),
        height: scaleWidth(149),
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.05)",
        borderRadius: scaleWidth(10),
        flexDirection: "row",
        display: "flex",
        backgroundColor: "#fff",
      }}
    >
      <img
        src={process.env.REACT_APP_BASE_MEDIA_URL + event.imgUrl}
        alt="Event"
        style={{
          height: scaleWidth(149),
          width: scaleWidth(103),
          borderTopLeftRadius: scaleWidth(10),
          borderBottomLeftRadius: scaleWidth(10),
        }}
      />

      <Spacer width={17} />

      <div
        style={{
          textAlign: "left",
          flex: 1,
        }}
      >
        <Spacer width={12} />
        <p
          style={{
            fontSize: scaleWidth(19),
            color: "#222",
            fontWeight: "bold",
          }}
        >
          {event.name}
        </p>
        <Spacer width={12} />
        {hostName && (
          <p
            style={{
              fontSize: scaleWidth(18),
              color: colors.primary,
              textDecoration: "underline",
            }}
          >
            {hostName}
          </p>
        )}
        <Spacer width={12} />
        <p
          style={{
            fontSize: scaleWidth(18),
            color: "#555",
          }}
        >
          {formatAddress(event.address)}
        </p>
        <Spacer width={18} />
        <p
          style={{
            fontSize: scaleWidth(18),
            color: "#555",
          }}
        >
          {formatDate(event.date)}
        </p>
      </div>

      <div
        onClick={toggleOptions}
        style={{
          position: "relative",
          top: scaleWidth(12),
          right: scaleWidth(15),
          width: scaleWidth(30),
          height: scaleWidth(30),
          justifyContent: "flex-end",
          display: "flex",
        }}
      >
        <img
          src={dotsIcon}
          alt="Options"
          style={{ width: scaleWidth(4), height: scaleWidth(17) }}
        />
        {showOptions && (
          <div
            onClick={(e) => e.stopPropagation()} // Prevents closing the options box when clicking inside it
            style={{
              position: "absolute",
              top: scaleWidth(20),
              right: 0,
              backgroundColor: "#fff",
              border: "1px solid #ccc",
              borderRadius: "4px",
              padding: "8px",
              boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
              zIndex: 1000,
            }}
          >
            <p
              style={{ margin: 0, cursor: "pointer" }}
              onClick={() => {
                onDelete(event.id);
                setShowOptions(false);
              }}
            >
              Elimina
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default EventCard;
