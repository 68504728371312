import React, { useEffect, useState } from "react";
import { deleteItem, getAllItems } from "../../../services/apiService";

import AddButton from "../../../components/AddButton";
import Header from "../../../components/Header";
import LocalCard from "../../../components/LocalCard";
import Spacer from "../../../components/Spacer";
import { fontScale } from "../../../utils/responsive";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "../../../components/LoadingScreen";

const styles = {
  localsScreen: {
    textAlign: "center",
    backgroundColor: "#f7f7f7",
    minHeight: "100vh",
  },
  loading: {
    fontSize: fontScale(20),
    color: "#4bc592",
    marginTop: "20px",
  },
};

const LocalsScreen = () => {
  const navigate = useNavigate();
  const [locals, setLocals] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchLocals = async () => {
      try {
        const response = await getAllItems("locals/owned");
        console.log("Fetched locals:", response.data);
        setLocals(response.data);
      } catch (error) {
        console.error("Error fetching locals:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchLocals();
  }, []);

  const handleAddLocalClick = () => {
    navigate("/dashboard/local/add");
  };

  const handleDeleteLocal = async (localId) => {
    const confirmDelete = window.confirm(
      "Sei sicuro di voler eliminare questo locale? Questa azione non può essere annullata."
    );

    if (confirmDelete) {
      try {
        await deleteItem(`locals/`, localId);
        // Update the state to remove the deleted local
        setLocals((prevLocals) =>
          prevLocals.filter((local) => local.id !== localId)
        );
        console.log("Local deleted successfully");
      } catch (error) {
        console.error("Error deleting local:", error);
      }
    } else {
      console.log("Eliminazione annullata");
    }
  };

  return (
    <div style={styles.localsScreen}>
      <Header type={"secondary"} title={"Host registrati"} />
      <Spacer height={20} />
      <div className="locals-list">
        {loading ? (
          <LoadingScreen />
        ) : (
          <>
            {locals.map((local) => (
              <LocalCard
                key={local.id}
                local={local}
                onClick={() => navigate(`/dashboard/local/${local.id}`)}
                onDelete={handleDeleteLocal}
              />
            ))}
            <AddButton
              onClick={handleAddLocalClick}
              text={locals.length === 0 ? "Crea nuovo Host" : "Aggiungi Host"}
            />
          </>
        )}

        <Spacer height={80} />
      </div>
    </div>
  );
};

export default LocalsScreen;
