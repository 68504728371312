import React, { useState } from "react";
import LocalInformation from "./LocalInformation";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../../components/Header";
import { updateItem } from "../../../services/apiService";

const LocalEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const local = location.state?.local;

  // Move formData to LocalEdit
  const [formData, setFormData] = useState({
    name: local?.name || "",
    address: local?.address || "",
    description: local?.description || "",
    website: local?.website || "",
    socials: {
      facebook: local?.socials?.facebook || "",
      instagram: local?.socials?.instagram || "",
      tiktok: local?.socials?.tiktok || "",
    },
    type: local?.type || "",
  });

  const onSave = async (e) => {
    e.preventDefault();

    const updatedLocalData = {
      name: formData.name,
      address: formData.address,
      description: formData.description,
      website: formData.website,
      socials: {
        facebook: formData.facebook,
        instagram: formData.instagram,
        tiktok: formData.tiktok,
      },
      type: formData.type,
    };

    try {
      console.log("updatedLocalData", updatedLocalData);
      await updateItem(`locals/`, local.id, updatedLocalData);
      navigate(-1);
    } catch (error) {
      console.error("Error updating local:", error);
    }
  };

  // Update formData based on changes in LocalInformation
  const handleFormDataChange = (name, value) => {
    console.log("name", name, "value", value);
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div>
      <Header
        type={"tertiary"}
        title={"Modifica host"}
        textAlign="left"
        hasBackButton={true}
        saveText={"Salva"}
        onSave={onSave}
      />
      <LocalInformation
        local={formData}
        readonly={false}
        onChange={handleFormDataChange}
      />
    </div>
  );
};

export default LocalEdit;
