import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Input from "../../../components/Input";
import Header from "../../../components/Header";
import { scaleWidth } from "../../../utils/responsive";
import Spacer from "../../../components/Spacer";
import emailIcon from "../../../assets/icons/email-send.png";
import colors from "../../../assets/styles/colors";
import { updateItem } from "../../../services/apiService";

const ProfileEditScreen = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const initialFormValues = state?.formValues || {};
  const hostId = initialFormValues.hostId;

  const [formValues, setFormValues] = useState(initialFormValues);

  const handleInputChange = (field, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const onSave = async () => {
    console.log("Saving form data...", formValues);
    const updatedHostData = {
      name: formValues.companyName,
      vat: formValues.vatNumber,
      administrator: formValues.adminName,
      phone: formValues.phoneNumber,
    };

    try {
      await updateItem(`hosts`, hostId, updatedHostData); // Updating the host information
      // You can add navigation or success message logic here
      console.log("Host updated successfully");
      navigate(-1);
    } catch (error) {
      console.error("Error updating host:", error);
    }
  };

  return (
    <div>
      <Header
        type={"tertiary"}
        title={"Modifica profilo"}
        textAlign="left"
        hasBackButton={true}
        saveText={"Salva"}
        onSave={onSave}
      />
      <div
        style={{
          paddingLeft: scaleWidth(28),
          paddingRight: scaleWidth(28),
          paddingTop: scaleWidth(34),
        }}
      >
        {/* Render the same form but with inputs editable */}
        <Input
          label={"Ragione Sociale"}
          placeholder='Es. "Mario Rossi SPA"'
          type="text"
          value={formValues.companyName || ""}
          onChange={(e) => handleInputChange("companyName", e.target.value)}
        />
        <Spacer height={30} />
        <div style={{ width: scaleWidth(170) }}>
          <Input
            label={"Codice P.IVA"}
            placeholder="Inserisci P.IVA"
            type="text"
            value={formValues.vatNumber}
            onChange={(e) => handleInputChange("vatNumber", e.target.value)}
          />
        </div>
        <Spacer height={30} />
        <Input
          label={"Intestatario P.IVA"}
          placeholder="Nome e Cognome"
          type="text"
          value={formValues.adminName}
          onChange={(e) => handleInputChange("adminName", e.target.value)}
        />
        <Spacer height={30} />
        <Input
          label={"Numero di telefono"}
          placeholder="Inserisci il numero di telefono"
          type="tel"
          prefixText="+39"
          value={formValues.phoneNumber}
          onChange={(e) => handleInputChange("phoneNumber", e.target.value)}
        />
        <Spacer height={30} />
        <Input
          label={"Indirizzo PEC"}
          placeholder="Inserisci PEC"
          type="text"
          value={formValues.pec}
          onChange={(e) => handleInputChange("phoneNumber", e.target.value)}
        />
        <Spacer height={134} />
      </div>

      <div
        style={{
          position: "fixed",
          bottom: 0,
          height: scaleWidth(94),
          backgroundColor: "white",
          boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.05)",
          width: "100%",
          flexDirection: "row",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Spacer width={36} />
        <img
          src={emailIcon}
          alt="Email"
          style={{ width: scaleWidth(40), height: scaleWidth(40) }}
        />
        <Spacer width={17} />
        <p
          style={{
            fontSize: scaleWidth(15),
            color: "#222",
            fontWeight: 500,
            width: scaleWidth(262),
          }}
        >
          Hai notato anomalie e vuoi segnalare un problema riscontrato sul tuo
          profilo? Contattaci su{" "}
          <span style={{ color: colors.primary }}>info@eventoapp.it</span>
        </p>
        <Spacer width={32} />
      </div>
    </div>
  );
};

export default ProfileEditScreen;
