import { fontScale, scaleHeight, scaleWidth } from "../utils/responsive";

import React from "react";
import Spacer from "./Spacer";

const AddButton = ({ onClick, text }) => {
  const styles = {
    addButton: {
      width: scaleWidth(226),
      height: scaleHeight(55),
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#fff",
      borderRadius: scaleHeight(55),
      boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.08)",
      flexDirection: "row",
      display: "flex",
      position: "fixed",
      bottom: scaleWidth(88),
      left: "50%",
      transform: "translateX(-50%)",
    },
    buttonText: {
      fontSize: fontScale(19),
      color: "#ea802f",
      fontWeight: "700",
    },
    plusIcon: {
      fontSize: fontScale(25),
      height: scaleWidth(30),
      width: scaleWidth(30),
      borderRadius: scaleWidth(30),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#fff",
      backgroundColor: "#ea802f",
    },
  };

  return (
    <div style={styles.addButton} onClick={onClick}>
      <p style={styles.buttonText}>{text}</p>
      <Spacer width={8} />
      <span style={styles.plusIcon}>+</span>
    </div>
  );
};

export default AddButton;
