const colors = {
  primary: "#ea802f",
  black: "#222",
  white: "#fff",
  transparent: "transparent",

  primaryDark: "#272132",
  grey: "#707070",
  lightGrey: "#F3F3F3",
  primaryPurple: "#8235BF",
  secondaryPurple: "#A544F2",
  lightPurple: "#DBBEF2",
};

export default colors;
