import React, { useEffect, useMemo, useState } from "react";
import { fontScale, scaleWidth } from "../../../utils/responsive";
import { useLocation, useNavigate } from "react-router-dom";

import Autocomplete from "react-google-autocomplete";
import CustomRadioButton from "../../../components/CustomRadioButton";
import Header from "../../../components/Header";
import Input from "../../../components/Input";
import Label from "../../../components/Label";
import Spacer from "../../../components/Spacer";
import BottomNavBar from "../../../components/BottomNavBar";
import linkIcon from "../../../assets/icons/link.png";
import colors from "../../../assets/styles/colors";

const Divider = React.memo(() => (
  <hr
    style={{
      width: scaleWidth(390),
      marginLeft: scaleWidth(-35),
      border: "none",
      borderTop: "1px solid #efefef",
    }}
  />
));

const AddEventScreen = ({ mode = "create", editData, setEditedFormData }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const local = location.state?.local || {};
  const passedFormData = location.state?.formData || {};

  const [formData, setFormData] = useState({
    name: "",
    host: "",
    localId: local.id || null,
    address: local.address || "",
    addressObj: null,
    lat: local.lat || "",
    lng: local.lng || "",
    date: "",
    endDate: "",
    startTime: "",
    endTime: "",
    price: "",
    // parkingPrice: 0,
    isParkingFree: true,
    category: "",
    description: "",
    imgUrl: "",
    isParkingSecure: false,
    parkingAddress: "",
    locationType: "same",
    ticketUrl: "",
    ...passedFormData,
  });

  useEffect(() => {
    if (mode === "edit" && editData) {
      setFormData((prev) => ({
        ...prev,
        ...editData,
        localId: editData.localId || local.id || null,
      }));
    }
  }, [mode, local.id]);

  useEffect(() => {
    if (setEditedFormData) {
      setEditedFormData({ ...formData });
    }
  }, [formData]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({ ...formData, [name]: type === "checkbox" ? checked : value });
  };

  const handleLocationChange = (e) => {
    const { value } = e.target;
    if (value === "same") {
      setFormData({
        ...formData,
        locationType: value,
        address: local.address,
        lat: local.lat,
        lng: local.lng,
        addressObj: null, // Reset addressObj when using the same location as the local
      });
    } else {
      setFormData({
        ...formData,
        locationType: value,
        address: "",
        lat: "",
        lng: "",
        addressObj: null, // Reset addressObj for different location
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/dashboard/event/add/more", {
      state: { eventData: formData },
    });
  };

  const handleBack = () => {
    navigate("/dashboard");
  };

  return (
    <div>
      {mode === "create" && (
        <Header
          title="CREA NUOVO EVENTO 1/2"
          hasBackButton
          onBack={handleBack}
        />
      )}

      <div
        style={{
          paddingRight: scaleWidth(30),
          paddingLeft: scaleWidth(35),
          paddingTop: scaleWidth(31),
          paddingBottom: scaleWidth(10),
        }}
      >
        {mode === "create" && (
          <div>
            <h2>Completa le informazioni e pubblica il tuo Evento</h2>
            <p>
              Le informazioni che inserirai saranno fondamentali per indicizzare
              il tuo evento all’interno della piattaforma.
            </p>
            <Spacer height={61} />
          </div>
        )}

        <form>
          <Input
            label="Titolo dell'evento"
            placeholder="Inserisci titolo"
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />

          <Spacer height={54} />

          <Divider />

          <Spacer height={39} />

          <Label text="Dove si svolgerà l'evento?" />

          <div>
            {local.id !== "none" && (
              <>
                <Spacer height={20} />
                <CustomRadioButton
                  id="sameLocation"
                  name="locationType"
                  value="same"
                  checked={formData.locationType === "same"}
                  onChange={handleLocationChange}
                  label="Il luogo dell'evento coincide con quello dell'Host"
                />
                <Spacer height={22} />
                <CustomRadioButton
                  id="differentLocation"
                  name="locationType"
                  value="different"
                  checked={formData.locationType === "different"}
                  onChange={handleLocationChange}
                  label="Il luogo dell'evento è differente da quello dell'Host"
                />
              </>
            )}
          </div>
          {(formData.locationType === "different" || local.id === "none") && (
            <>
              <Spacer height={19} />
              <Autocomplete
                apiKey={process.env.REACT_APP_GOOGLE}
                placeholder="Inserisci indirizzo"
                onPlaceSelected={(place) => {
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    address: place.formatted_address,
                    // addressObj: JSON.stringify(place), // Save the full address object
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                  }));
                }}
                language="it"
                debounce={500}
                options={{
                  types: ["address"],
                  componentRestrictions: { country: "it" },
                }}
                style={{
                  fontFamily: "Lato",
                  fontSize: fontScale(20),
                  color: "#999",
                  fontWeight: 300,
                }}
              />
            </>
          )}

          <Spacer height={60} />

          <Divider />

          <Spacer height={39} />

          <Label text="Giorno/i e orario di svolgimento" />

          <Spacer height={32} />

          {/* Startdate */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <Label
              text="Dal"
              style={{
                width: scaleWidth(37),
              }}
            />
            <Input
              type="date"
              name="date"
              value={formData.date}
              onChange={handleChange}
              style={{ width: scaleWidth(129) }}
            />
            <Label
              text="Ore"
              style={{
                width: scaleWidth(37),
              }}
            />
            <Input
              type="time"
              name="startTime"
              value={formData.startTime}
              onChange={handleChange}
            />
          </div>

          <Spacer height={17} />

          {/* Enddate */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <Label
              text="Dal"
              style={{
                width: scaleWidth(37),
              }}
            />
            <Input
              type="date"
              name="endDate"
              value={formData.endDate}
              onChange={handleChange}
              style={{ width: scaleWidth(129) }}
            />
            <Label
              text="Ore"
              style={{
                width: scaleWidth(37),
              }}
            />
            <Input
              type="time"
              name="endTime"
              value={formData.endTime}
              onChange={handleChange}
            />
          </div>

          <Spacer height={39} />

          <Divider />

          <Spacer height={29} />

          <Label text="Prezzo del biglietto" />

          <Spacer height={45} />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Label
              text="Imposta prezzo"
              style={{
                fontWeight: 500,
              }}
            />
            <Spacer width={12} />
            <Input
              placeholder="___€"
              type="number"
              name="price"
              value={formData.price}
              onChange={handleChange}
              style={{ width: scaleWidth(69) }}
            />
          </div>

          <Spacer height={27} />

          <Input
            icon={linkIcon}
            placeholder="Inserisci link"
            type="url"
            name="ticketUrl"
            value={formData.ticketUrl}
            onChange={handleChange}
          />
          <Spacer height={10} />
          <i
            style={{
              fontSize: fontScale(17),
              lineHeight: fontScale(1.4),
              color: "#555",
              display: "block",
            }}
          >
            Puoi inserire il link diretto per migrare gli utenti verso il tuo{" "}
            <span style={{ color: colors.primary }}>
              portale di vendita biglietti
            </span>
            .
          </i>

          <Spacer height={39} />

          <Divider />

          <Spacer height={30} />

          <Label text="Indicazioni parcheggio (se presente)" />
          <Spacer height={36} />

          <CustomRadioButton
            id="isParkingFree"
            name="isParkingFree"
            value={formData.isParkingFree}
            checked={formData.isParkingFree}
            onChange={handleChange}
            label="Gratuito"
          />

          <Spacer height={20} />

          <CustomRadioButton
            id="isParkingSecure"
            name="isParkingSecure"
            value={formData.isParkingSecure}
            checked={formData.isParkingSecure}
            onChange={handleChange}
            label="Custodito"
          />

          <Spacer height={20} />

          <Autocomplete
            apiKey={process.env.REACT_APP_GOOGLE}
            placeholder="Inserisci indirizzo"
            onPlaceSelected={(place) => {
              setFormData((prevFormData) => ({
                ...prevFormData,
                parkingAddress: place.formatted_address,
              }));
            }}
            language="it"
            debounce={500}
            options={{
              types: ["address"],
              componentRestrictions: { country: "it" },
            }}
            style={{
              fontFamily: "Lato",
              fontSize: fontScale(20),
              color: "#999",
              fontWeight: 300,
            }}
            onChange={() => {}}
            value={formData.parkingAddress}
          />
          <Spacer height={10} />
          <i
            style={{
              fontSize: fontScale(17),
              lineHeight: fontScale(1.4),
              color: "#555",
              display: "block",
            }}
          >
            Inserisci l’indirizzo del parcheggio per aiutare gli utenti a
            trovarlo più facilmente.
          </i>

          <Spacer height={150} />
          {mode === "create" && (
            <>
              <BottomNavBar
                onBack={handleBack}
                onNext={handleSubmit}
                showBackButton={true}
                nextLabel={"Continua"}
                showArrow={true}
              />
              <Spacer height={80} />
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export default AddEventScreen;
