import React, { useState } from "react";
import { fontScale, scaleWidth } from "../../../utils/responsive";

import Autocomplete from "react-google-autocomplete";
import BottomNavBar from "../../../components/BottomNavBar";
import Header from "../../../components/Header";
import Input from "../../../components/Input";
import Label from "../../../components/Label";
import Spacer from "../../../components/Spacer";
import fbicon from "../../../assets/icons/fb.png";
import instaIcon from "../../../assets/icons/ig.png";
import tiktokIcon from "../../../assets/icons/tt.png";
import { useNavigate } from "react-router-dom";
import webIcon from "../../../assets/icons/web.png";
import Select from "../../../components/Select";

const AddLocalScreen = () => {
  const navigate = useNavigate();

  // Form data and error states
  const [formData, setFormData] = useState({
    name: "",
    type: "",
    address: "",
    addressObj: null,
    lat: 0,
    lng: 0,
    website: "",
    facebook: "",
    instagram: "",
    tiktok: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Validation logic
  const validate = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Nome locale è richiesto";
    if (!formData.type) newErrors.type = "Tipologia di locale è richiesta";
    if (!formData.address) newErrors.address = "Indirizzo è richiesto";
    return newErrors;
  };

  const handleNext = () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors); // Set validation errors
      return;
    }

    const { addressObj, ...serializableFormData } = formData;
    navigate("/dashboard/local/add/details", { state: serializableFormData });
  };

  return (
    <div>
      <Header
        title={"CREA NUOVO HOST 1/2"}
        hasBackButton={true}
        textAlign={"left"}
      />

      <div
        style={{
          paddingRight: scaleWidth(30),
          paddingLeft: scaleWidth(35),
          paddingTop: scaleWidth(31),
          paddingBottom: scaleWidth(10),
        }}
      >
        <h2
          style={{
            fontSize: fontScale(30),
            color: "#222",
            fontWeight: "bold",
          }}
        >
          Raccontaci del tuo Host e aggiungi informazioni
        </h2>
        <Spacer height={5} />
        <p
          style={{
            fontSize: fontScale(20),
            color: "#222",
          }}
        >
          Le informazioni che inserirai saranno fondamentali per indicizzare il
          tuo locale all’interno della piattaforma.
        </p>
        <Spacer height={46} />

        <div>
          <Input
            label="Nome dell'Host*"
            placeholder="Inserisci nome locale"
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
          {errors.name && <span style={{ color: "red" }}>{errors.name}</span>}
          <Spacer height={30} />

          <Label text="Tipologia di Host*" />
          {/* <select
            name="type"
            value={formData.type}
            onChange={handleChange}
            style={{
              color: formData.type === "" ? "#D0D0D0" : "#222",
              backgroundColor: "#fff",
              fontSize: fontScale(20),
              borderColor: "#D0D0D0",
              borderWidth: 0.5,
              borderRadius: 10,
              padding: scaleWidth(15),
              width: "100%",
              borderStyle: "solid",
              fontFamily: "Lato",
              fontWeight: "100",
            }}
          >
            <option value="" disabled>
              Seleziona tipologia
            </option>
            <option value="disco">Discoteca</option>
            <option value="bar">Bar</option>
            <option value="lounge">Lounge Bar</option>
            <option value="other">Altro</option>
          </select> */}
          <Select
            name="type"
            value={formData.type.value}
            onChange={handleChange}
            placeholder={"Seleziona tipologia"}
            options={[
              { value: "bar", label: "Bar" },
              { value: "beachclub", label: "Beach Club" },
              { value: "caffe", label: "Caffè" },
              { value: "disco", label: "Discoteca" },
              { value: "wineshop", label: "Enoteca" },
              { value: "fastfood", label: "Fast Food" },
              { value: "icecream", label: "Gelateria" },
              { value: "lounge", label: "Lounge Bar" },
              { value: "nightclub", label: "Night Club" },
            ]}
          />
          {errors.type && <span style={{ color: "red" }}>{errors.type}</span>}
          <Spacer height={30} />

          <Label text="Indirizzo dell'Host*" />
          <Autocomplete
            apiKey={process.env.REACT_APP_GOOGLE}
            style={{
              color: formData.address ? "#222" : "#A0A0A0",
              fontSize: fontScale(20),
              borderColor: "#D0D0D0",
              borderWidth: 0.5,
              borderRadius: 10,
              padding: scaleWidth(15),
              borderStyle: "solid",
              fontFamily: "Lato",
              fontWeight: "100",
            }}
            onPlaceSelected={(place) => {
              setFormData((prevFormData) => ({
                ...prevFormData,
                address: place.formatted_address,
                addressObj: place,
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
              }));
            }}
            language="it"
            debounce={500}
            options={{
              types: ["address"],
              componentRestrictions: { country: "it" },
            }}
          />
          {errors.address && (
            <span style={{ color: "red" }}>{errors.address}</span>
          )}
          <Spacer height={30} />

          <Input
            label="Sito Web"
            placeholder="Inserisci link URL"
            type="text"
            name="website"
            value={formData.website}
            onChange={handleChange}
            icon={webIcon}
          />
          <Spacer height={30} />
          <Input
            label="Social Network"
            placeholder="Inserisci link URL"
            type="text"
            name="facebook"
            value={formData.facebook}
            onChange={handleChange}
            icon={fbicon}
          />
          <Spacer height={5} />
          <Input
            label=""
            placeholder="Inserisci link URL"
            type="text"
            name="instagram"
            value={formData.instagram}
            onChange={handleChange}
            icon={instaIcon}
          />
          <Spacer height={5} />
          <Input
            label=""
            placeholder="Inserisci link URL"
            type="text"
            name="tiktok"
            value={formData.tiktok}
            onChange={handleChange}
            icon={tiktokIcon}
          />
          <Spacer height={200} />

          <BottomNavBar
            onBack={() => navigate(-1)}
            onNext={handleNext}
            showBackButton={true}
            nextLabel={"Continua"}
            showArrow={true}
          />
        </div>
      </div>
    </div>
  );
};

export default AddLocalScreen;
