import { fontScale, scaleWidth } from "../../utils/responsive";

import PropTypes from "prop-types";
import React from "react";
import accountRole from "../../assets/icons/account-role.png";
import colors from "../../assets/styles/colors";

const TypeBox = ({ title, description, selected = true, onClick }) => {
  return (
    <div
      onClick={onClick}
      style={{
        border: selected
          ? `2px solid ${colors.primary}`
          : `2px solid ${colors.transparent}`,
        borderRadius: scaleWidth(20),
        boxShadow: selected ? "none" : "0px 4px 6px rgba(0, 0, 0, 0.05)", // Shadow when not selected
        padding: scaleWidth(20),
        cursor: "pointer",
        position: "relative", // To position the circle
        backgroundColor: "#fff", // Background color for the box
        marginLeft: scaleWidth(33),
        marginRight: scaleWidth(40),
      }}
    >
      {/* Title */}
      <h2
        style={{
          fontSize: fontScale(20),
          color: colors.black,
          lineHeight: "135%",
          whiteSpace: "pre-line",
        }}
      >
        {title}
      </h2>

      {/* Spacer */}
      <div style={{ height: scaleWidth(5) }} />

      {/* Description */}
      <p
        style={{
          fontSize: fontScale(19),
          color: colors.grey,
          fontStyle: "italic",
          lineHeight: "125%",
        }}
      >
        {description}
      </p>

      {/* White circle in the top right corner */}
      <div
        style={{
          width: scaleWidth(52),
          height: scaleWidth(52),
          borderRadius: "50%",
          backgroundColor: "#fff",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.05)", // Shadow for the circle
          position: "absolute",
          top: scaleWidth(-15), // Adjust to move the circle upwards
          right: scaleWidth(-10), // Adjust to move the circle to the right
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={accountRole} alt="Icona" style={{ width: scaleWidth(24) }} />
      </div>
    </div>
  );
};

TypeBox.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  selected: PropTypes.bool, // Controls border and shadow state
  onClick: PropTypes.func.isRequired,
  circleContent: PropTypes.node, // Placeholder for optional image or content in the circle
};

export default TypeBox;
