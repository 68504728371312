import React, { useEffect, useState } from "react";
import { filterItems, getAllItems } from "../../../services/apiService";
import { fontScale, scaleWidth } from "../../../utils/responsive";

import Button from "../../../components/Button";
import Header from "../../../components/Header";
import Input from "../../../components/Input";
import Spacer from "../../../components/Spacer";
import accountPlaceholder from "../../../assets/imgs/account-placeholder.png";
import authService from "../../../services/authService";
import { useNavigate } from "react-router-dom";
import waitingImg from "../../../assets/imgs/waiting.png";

import AddButton from "../../../components/AddButton";
import dotsVertical from "../../../assets/icons/dots-vertical.png";

// Added updateItem

const ProfileTabs = ({ activeTab, setActiveTab }) => {
  return (
    <div
      className="tabs"
      style={{
        boxShadow: "0px 5px 8px 0px rgba(0,0,0,0.04)",
      }}
    >
      <button
        style={{
          width: "100%",
          fontSize: fontScale(18),
          backgroundColor: "#fff",
          borderBottom:
            activeTab === "ongoing" ? "2px solid #000" : "2px solid #fff",
          fontWeight: activeTab === "ongoing" ? "700" : "normal",
          height: scaleWidth(42),
          fontFamily: "Lato",
          color: activeTab === "ongoing" ? "#222" : "#555",
        }}
        onClick={() => setActiveTab("ongoing")}
      >
        Dati dell'Account
      </button>
      <button
        style={{
          width: "100%",
          fontSize: fontScale(18),
          backgroundColor: "#fff",
          borderBottom:
            activeTab === "past" ? "2px solid #000" : "2px solid #fff",
          height: scaleWidth(42),
          fontWeight: activeTab === "past" ? "700" : "normal",
          fontFamily: "Lato",
          color: activeTab === "past" ? "#222" : "#555",
        }}
        onClick={() => setActiveTab("past")}
      >
        Statistiche
      </button>
    </div>
  );
};
const ProfileCard = ({ locals, events, host }) => {
  const navigate = useNavigate();
  const [showLogoutOptions, setShowLogoutOptions] = useState(false);

  const toggleLogoutOptions = (e) => {
    e.stopPropagation(); // Prevents card click from firing
    setShowLogoutOptions((prev) => !prev);
  };

  const closeLogoutOptions = () => {
    setShowLogoutOptions(false);
  };

  const handleLogout = async () => {
    const confirmed = true;
    if (confirmed) {
      try {
        await authService.signOut();
        navigate("/login");
      } catch (error) {
        console.error("Error logging out:", error);
      }
    }
  };

  return (
    <div
      onClick={closeLogoutOptions} // Close options box when clicking the card
      style={{
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
        borderRadius: "10px",
        width: scaleWidth(342),
        height: scaleWidth(101),
        marginLeft: scaleWidth(20),
        borderTopLeftRadius: "100px",
        borderBottomLeftRadius: "100px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <div className="badge">
          <img
            style={{
              width: scaleWidth(86),
              height: scaleWidth(86),
              borderRadius: "50%",
              marginTop: scaleWidth(7),
              marginLeft: scaleWidth(8),
              marginRight: scaleWidth(17),
              backgroundColor: "#f6f6f6",
            }}
            src={accountPlaceholder}
            alt="Verified"
          />
        </div>

        <div
          style={{
            height: scaleWidth(101),
            width: "100%",
          }}
        >
          <Spacer height={22} />
          <div className="card-title">{host.administrator ?? ""}</div>
          <Spacer height={5} />
          <div className="card-subtitle">
            {host.role === "owner" ? (
              <>
                <strong style={{ color: "#000" }}>{locals}</strong> Locali |{" "}
              </>
            ) : (
              ""
            )}
            <strong style={{ color: "#000" }}>{events}</strong> Eventi
          </div>
        </div>

        <div
          onClick={toggleLogoutOptions}
          style={{ position: "relative", width: scaleWidth(20) }}
        >
          <img
            src={dotsVertical}
            style={{
              width: scaleWidth(3.2),
              marginRight: scaleWidth(15),
            }}
            alt="Options"
          />

          {showLogoutOptions && (
            <div
              onClick={(e) => e.stopPropagation()} // Prevents closing when clicking inside options box
              style={{
                position: "absolute",
                top: scaleWidth(0),
                right: scaleWidth(15),
                backgroundColor: "#fff",
                border: "1px solid #ccc",
                borderRadius: "4px",
                padding: "8px",
                boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                zIndex: 1000,
              }}
            >
              <p
                style={{ margin: 0, cursor: "pointer" }}
                onClick={() => {
                  handleLogout();
                  setShowLogoutOptions(false);
                }}
              >
                Logout
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const ProfileScreen = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("ongoing");
  const [locals, setLocals] = useState(0);
  const [events, setEvents] = useState(0);
  const [host, setHost] = useState({});
  const [formValues, setFormValues] = useState({
    hostId: "",
    companyName: "",
    vatNumber: "",
    adminName: "",
    phoneNumber: "",
    pec: "",
    role: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = authService.getCurrentUser();

        const localsResponse = await getAllItems("locals/owned");
        setLocals(localsResponse.data.length);

        const eventsResponse = await getAllItems("events/owned");
        setEvents(eventsResponse.data.length);

        const hostResponse = await filterItems("hosts", {
          attribute: "firebaseUid",
          value: user.uid,
        });
        const u = hostResponse.data[0];
        console.log("Host data:", u);
        setHost(u);
        setFormValues({
          hostId: u.id,
          companyName: u.name || "",
          vatNumber: u.vat || "",
          adminName: u.administrator || "",
          phoneNumber: u.phone || "",
          pec: u.pec || "",
          role: u.role,
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (field, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const onEditClick = () => {
    navigate("/dashboard/profile/edit", { state: { formValues } });
  };

  const handleAddLocalClick = () => {
    navigate("/dashboard/local/add");
  };

  const handleAddEventClick = () => {
    navigate("/dashboard/event/add", {
      state: { local: { id: "none" } },
    });
  };

  return (
    <div>
      <Header type={"secondary"} title={"Profilo utente"} />
      <Spacer height={20} />
      <ProfileCard locals={locals} events={events} host={host ?? {}} />
      <Spacer height={20} />

      <ProfileTabs activeTab={activeTab} setActiveTab={setActiveTab} />
      <Spacer height={20} />

      {activeTab === "ongoing" ? (
        <div>
          <div
            style={{
              paddingRight: scaleWidth(40),
              paddingLeft: scaleWidth(40),
              paddingTop: scaleWidth(20),
            }}
          >
            <div>
              <Input
                label={"Ragione Sociale"}
                placeholder='Es. "Mario Rossi SPA"'
                type="text"
                value={formValues.companyName}
                readonly={true}
                onChange={(e) =>
                  handleInputChange("companyName", e.target.value)
                }
              />
              <Spacer height={30} />
              <div style={{ width: scaleWidth(170) }}>
                <Input
                  label={"Codice P.IVA"}
                  placeholder="Inserisci P.IVA"
                  type="text"
                  value={formValues.vatNumber}
                  readonly={true}
                  onChange={(e) =>
                    handleInputChange("vatNumber", e.target.value)
                  }
                />
              </div>
              <Spacer height={30} />
              <Input
                label={"Intestatario P.IVA"}
                placeholder="Nome e Cognome"
                type="text"
                value={formValues.adminName}
                readonly={true}
                onChange={(e) => handleInputChange("adminName", e.target.value)}
              />
              <Spacer height={30} />
              <Input
                label={"Numero di telefono"}
                placeholder="Inserisci il numero di telefono"
                type="tel"
                prefixText="+39"
                value={formValues.phoneNumber}
                readonly={true}
                onChange={(e) =>
                  handleInputChange("phoneNumber", e.target.value)
                }
              />
              <Spacer height={30} />
              <Input
                label={"Indirizzo PEC"}
                placeholder=""
                type="text"
                value={formValues.pec}
                readonly={true}
                onChange={(e) =>
                  handleInputChange("phoneNumber", e.target.value)
                }
              />
              <Spacer height={35} />
              <div style={{ textAlign: "center" }}>
                <Button
                  type="primary"
                  style={{ width: scaleWidth(163), height: scaleWidth(54) }}
                  onClick={onEditClick}
                >
                  Modifica dati
                </Button>
              </div>
            </div>
            <Spacer height={50} />
            <Spacer height={50} />
            <p>v0.2.0</p>
            <Spacer height={100} />
          </div>
        </div>
      ) : (
        <div
          style={{
            textAlign: "center",
            padding: "20px",
          }}
        >
          <Spacer height={10} />
          <img
            src={waitingImg}
            alt="Work in progress"
            style={{ width: scaleWidth(191), height: "auto" }}
          />
          <h2>A volte serve tempo</h2>
          <Spacer height={10} />
          <p style={{ fontSize: fontScale(19) }}>
            I dati che ci fornirai saranno utilizzati esclusivamente per la tua
            registrazione
          </p>
          {formValues.role === "owner" && (
            <AddButton onClick={handleAddLocalClick} text="Crea nuovo Host" />
          )}
          {formValues.role === "organizator" && (
            <AddButton onClick={handleAddEventClick} text="Crea nuovo Evento" />
          )}
        </div>
      )}
    </div>
  );
};

export default ProfileScreen;
