import React from "react";
import { fontScale, scaleWidth } from "../../../utils/responsive";

import Button from "../../../components/Button";
import Input from "../../../components/Input";
import Label from "../../../components/Label";
import Spacer from "../../../components/Spacer";

import tiktokIcon from "../../../assets/icons/tt.png";
import instaIcon from "../../../assets/icons/ig.png";
import webIcon from "../../../assets/icons/web.png";
import fbIcon from "../../../assets/icons/fb.png";
import colors from "../../../assets/styles/colors";
import emailIcon from "../../../assets/icons/email-icon-white.png";
import { useNavigate } from "react-router-dom";

const LocalInformation = ({ local, readonly, onChange }) => {
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    onChange(name, value);
  };

  const getType = (type) => {
    switch (type) {
      case "bar":
        return "Bar";
      case "beachclub":
        return "Beach Club";
      case "caffe":
        return "Caffè";
      case "disco":
        return "Discoteca";
      case "wineshop":
        return "Enoteca";
      case "fastfood":
        return "Fast Food";
      case "icecream":
        return "Gelateria";
      case "lounge":
        return "Lounge Bar";
      case "nightclub":
        return "Night Club";
      default:
        return "Altro";
    }
  };

  return (
    <div>
      <div
        style={{
          paddingRight: scaleWidth(40),
          paddingLeft: scaleWidth(40),
          paddingTop: scaleWidth(20),
        }}
      >
        <div>
          <Input
            label="Nome dell'Host"
            placeholder="Inserisci il nome del locale"
            type="text"
            name="name"
            readonly={readonly}
            value={local.name}
            onChange={handleChange}
          />
          <Spacer height={20} />
          <Input
            label="Tipologia di Host"
            placeholder="Inserisci l'indirizzo"
            type="text"
            name="type"
            readonly={readonly}
            value={getType(local.type)}
            onChange={handleChange}
          />
          <Spacer height={20} />
          <Input
            label="Indirizzo dell'Host"
            placeholder="Inserisci l'indirizzo"
            type="text"
            name="address"
            readonly={readonly}
            value={local.address}
            onChange={handleChange}
          />

          <Spacer height={20} />
          <Input
            label="Sito Web"
            placeholder=""
            type="url"
            name="website"
            readonly={readonly}
            icon={webIcon}
            value={local.website}
            onChange={handleChange}
          />
          <Spacer height={20} />
          <Input
            label="Social Network"
            placeholder=""
            type="url"
            name="facebook"
            readonly={readonly}
            value={local.facebook}
            icon={fbIcon}
            onChange={handleChange}
          />
          <Spacer height={20} />
          <Input
            label=""
            placeholder=""
            type="url"
            name="instagram"
            readonly={readonly}
            value={local.instagram}
            icon={instaIcon}
            onChange={handleChange}
          />
          <Spacer height={20} />
          <Input
            label=""
            placeholder=""
            type="url"
            name="tiktok"
            readonly={readonly}
            icon={tiktokIcon}
            value={local.tiktok}
            onChange={handleChange}
          />
          <Spacer height={20} />
          <Label text={"Descrizione del locale"} />
          <textarea
            placeholder="Inserisci la descrizione"
            name="description"
            value={local.description}
            onChange={readonly ? null : handleChange}
            style={{
              color: local.description ? "#222" : "#D0D0D0",
              fontSize: fontScale(20),
              backgroundColor: readonly ? "#f9f9f9" : "#fff",
              opacity: 1,
              borderWidth: readonly ? 0 : 0.5,
              borderRadius: 6,
              padding: scaleWidth(15),
              height: scaleWidth(151),
              width: "90%",
              borderStyle: "solid", // Ensure the border style is solid
              fontFamily: "Lato",
            }}
            readonly={readonly}
          />
          <Spacer height={30} />
          {readonly && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                style={{
                  width: scaleWidth(237),
                  height: scaleWidth(54),
                }}
                type="action"
                onClick={() => {
                  console.log("Submit", local);
                  navigate("/dashboard/local/edit", {
                    state: { local: local },
                  });
                }}
              >
                Modifica informazioni
              </Button>
            </div>
          )}

          <Spacer height={120} />
        </div>
      </div>

      <div
        style={{
          position: "fixed",
          bottom: 0,
          height: scaleWidth(65),
          backgroundColor: colors.primary,
          width: "100%",
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Spacer width={36} />
        <img
          src={emailIcon}
          alt="Email"
          style={{ width: scaleWidth(40), height: scaleWidth(40) }}
        />
        <Spacer width={17} />
        <p
          style={{
            fontSize: scaleWidth(15),
            color: "#fff",
            fontWeight: 500,
          }}
        >
          Vuoi segnalare un problema riscontrato nel tuo Host? Contattaci su{" "}
          <u style={{ color: colors.white }}>info@eventoapp.it</u>
        </p>
        <Spacer width={32} />
      </div>
    </div>
  );
};

export default LocalInformation;
