import "./Local.css"; // Import CSS for styling

import React, { useEffect, useState } from "react";
import {
  deleteItem,
  filterItems,
  getItemById,
} from "../../../services/apiService";
import { fontScale, scaleWidth } from "../../../utils/responsive";
import { useNavigate, useParams } from "react-router-dom";

import AddButton from "../../../components/AddButton";
import LocalInformation from "./LocalInformation";
import Spacer from "../../../components/Spacer";
import axios from "axios";
import { updateItem } from "../../../services/apiService";
import arrowLeft from "../../../assets/icons/arrow-simple-left.png";
import plusCircle from "../../../assets/icons/plus-circle-orange.png";
import EventCard from "../../../components/EventCard";
import LoadingScreen from "../../../components/LoadingScreen";

const LocalHeader = ({ local, activeMainTab, setActiveMainTab, events }) => {
  const [logoUrl, setLogoUrl] = useState(local.logoUrl);
  const navigation = useNavigate();

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("img", file);

    try {
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + "/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const newLogoUrl = `/media/${response.data.filename}`;

      // Update the state with the new logo URL
      setLogoUrl(newLogoUrl);

      // Update the local in the database with the new logo URL
      await updateItem("locals", local.id, { logoUrl: newLogoUrl });
      console.log("Logo updated successfully");
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  return (
    <div className="local-header">
      <div className="local-header-info">
        <div
          style={{
            position: "absolute",
            left: 0,
            height: scaleWidth(66),
            width: scaleWidth(27),
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            borderTopRightRadius: scaleWidth(10),
            borderBottomRightRadius: scaleWidth(10),
            boxShadow: "0px 5px 8px 0px rgba(0,0,0,0.08)",
          }}
          onClick={() => navigation(-1)}
        >
          <img
            src={arrowLeft}
            alt="Back"
            style={{ height: scaleWidth(30), width: scaleWidth(25) }}
          />
        </div>

        <Spacer width={15} />

        <div
          className="logo-container"
          onClick={() => document.getElementById("logoUploadInput").click()}
        >
          <img
            src={process.env.REACT_APP_BASE_MEDIA_URL + logoUrl}
            alt="Logo"
            style={{
              height: scaleWidth(80),
              width: scaleWidth(80),
              borderRadius: scaleWidth(80),
              marginLeft: scaleWidth(10),
            }}
          />
          <div
            style={{
              position: "absolute",
              bottom: scaleWidth(-15),
              backgroundColor: "#fff",
              boxShadow: "0px 5px 8px 0px rgba(0,0,0,0.08)",
              width: scaleWidth(30),
              height: scaleWidth(30),
              borderRadius: scaleWidth(30),
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              marginLeft: scaleWidth(10),
            }}
          >
            <img
              src={plusCircle}
              alt="Add"
              style={{
                height: scaleWidth(23),
                width: scaleWidth(23),
                marginRight: scaleWidth(2),
                marginBottom: scaleWidth(1),
              }}
            />
          </div>
        </div>

        <input
          id="logoUploadInput"
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
          style={{ display: "none" }}
        />

        <div className="local-details">
          <h1 className="local-name">{local.name}</h1>
          <div className="local-stats">
            <span>
              <strong style={{ color: "#222" }}>{events.length ?? ""}</strong>{" "}
              Eventi
            </span>
            <span className="divider">|</span>
            <span>
              <strong style={{ color: "#222" }}>{0}</strong> Follower
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const EventTabs = ({
  activeEventTab,
  setActiveEventTab,
  ongoingLength,
  pastLength,
}) => {
  return (
    <div
      className="tabs"
      style={{
        boxShadow: "0px 5px 8px 0px rgba(0,0,0,0.04)",
      }}
    >
      <button
        style={{
          width: "100%",
          fontSize: fontScale(18),
          backgroundColor: "#fff",
          borderBottom:
            activeEventTab === "ongoing" ? "2px solid #000" : "2px solid #fff",
          fontWeight: activeEventTab === "ongoing" ? "700" : "normal",
          height: scaleWidth(42),
          fontFamily: "Lato",
          color: activeEventTab === "ongoing" ? "#222" : "#555",
        }}
        onClick={() => setActiveEventTab("ongoing")}
      >
        Prossimi eventi ({ongoingLength})
      </button>
      <button
        style={{
          width: "100%",
          fontSize: fontScale(18),
          backgroundColor: "#fff",
          borderBottom:
            activeEventTab === "past" ? "2px solid #000" : "2px solid #fff",
          fontWeight: activeEventTab === "past" ? "700" : "normal",
          height: scaleWidth(42),
          fontFamily: "Lato",
          color: activeEventTab === "past" ? "#222" : "#555",
        }}
        onClick={() => setActiveEventTab("past")}
      >
        Eventi conclusi ({pastLength})
      </button>
    </div>
  );
};

const EventsCard = ({
  events,
  local,
  onDelete,
  activeEventTab,
  setActiveEventTab,
}) => {
  const navigate = useNavigate();

  // Filter events into ongoing/future and past
  const now = new Date();
  const ongoingEvents = events.filter((event) => new Date(event.date) >= now);
  const pastEvents = events.filter((event) => new Date(event.date) < now);
  console.log("ongoingEvents", local.name);
  return (
    <div>
      <EventTabs
        activeEventTab={activeEventTab}
        setActiveEventTab={setActiveEventTab}
        ongoingLength={ongoingEvents.length}
        pastLength={pastEvents.length}
      />

      <div className="settings-section">
        {activeEventTab === "ongoing" &&
          (ongoingEvents.length > 0 ? (
            ongoingEvents.map((event) => (
              <div className="settings-item" key={event.id}>
                {/* <EventBox event={event} onDelete={onDelete} /> */}
                <EventCard
                  event={event}
                  onDelete={onDelete}
                  hostName={local.name}
                />
              </div>
            ))
          ) : (
            <></>
          ))}

        {activeEventTab === "past" &&
          (pastEvents.length > 0 ? (
            pastEvents.map((event) => (
              <div className="settings-item" key={event.id}>
                {/* <EventBox event={event} onDelete={onDelete} /> */}
                <EventCard
                  event={event}
                  onDelete={onDelete}
                  hostName={local.name}
                />
              </div>
            ))
          ) : (
            <></>
          ))}

        {activeEventTab !== "past" && (
          <AddButton
            onClick={() => {
              navigate("/dashboard/event/add", {
                state: { local },
              });
            }}
            text="Crea nuovo evento"
          />
        )}
      </div>
    </div>
  );
};

const LocalScreen = () => {
  const { id } = useParams();
  const [local, setLocal] = useState(null);
  const [events, setEvents] = useState([]);
  const [activeMainTab, setActiveMainTab] = useState("eventi");
  const [activeEventTab, setActiveEventTab] = useState("ongoing");
  const [showInfo, setShowInfo] = useState(false);

  useEffect(() => {
    const fetchLocalData = async () => {
      try {
        const localResponse = await getItemById("locals", id);
        setLocal(localResponse.data);
        const eventsResponse = await filterItems("events", {
          attribute: "localId",
          value: id,
        });
        setEvents(eventsResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchLocalData();
  }, [id]);

  const handleDelete = async (eventId) => {
    const confirmDelete = window.confirm(
      "Sei sicuro di voler eliminare questo evento? Questa azione non può essere annullata."
    );

    if (confirmDelete) {
      try {
        await deleteItem("events", eventId);
        setEvents(events.filter((e) => e.id !== eventId));
        console.log("Event deleted successfully");
      } catch (error) {
        console.error("Error deleting event:", error);
      }
    } else {
      console.log("Eliminazione annullata");
    }
  };

  const onInfoClick = () => {
    console.log("Info clicked");
    setShowInfo(!showInfo);
  };

  if (!local) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <LocalHeader
        local={local}
        activeMainTab={activeMainTab}
        setActiveMainTab={setActiveMainTab}
        events={events}
      />

      <EventsCard
        events={events}
        local={local}
        onDelete={handleDelete}
        activeEventTab={activeEventTab}
        setActiveEventTab={setActiveEventTab} // Pass the setter function here
      />

      <Spacer height={100} />

      <div
        style={{
          position: showInfo ? "absolute" : "fixed",
          bottom: 0,
          top: showInfo ? scaleWidth(10) : "auto",
          height: showInfo ? "auto" : scaleWidth(60),
          width: "100%",
          backgroundColor: "#fff",
          boxShadow: "0px -5px 8px 0px rgba(0,0,0,0.08)",
          borderTopLeftRadius: scaleWidth(20),
          borderTopRightRadius: scaleWidth(20),
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            width: scaleWidth(60),
            height: scaleWidth(4),
            backgroundColor: "#fff",
            boxShadow: "0px 5px 8px 0px rgba(0,0,0,0.08)",
            position: "absolute",
            top: scaleWidth(-4),
            borderTopLeftRadius: scaleWidth(20),
            borderTopRightRadius: scaleWidth(20),
          }}
          onClick={onInfoClick}
        >
          <div
            style={{
              position: "absolute",
              top: scaleWidth(4),
              width: scaleWidth(45),
              height: scaleWidth(3),
              backgroundColor: "#dedede",
              left: scaleWidth(7),
            }}
          />
        </div>

        <p
          style={{
            fontSize: fontScale(21),
            color: "#000",
            fontWeight: "900",
            height: showInfo ? scaleWidth(45) : scaleWidth(60),
            display: "flex",
            position: "absolute",
            top: 16,
            width: "100%",
            justifyContent: "center",
            boxShadow: "0px 5px 8px 0px rgba(0,0,0,0.04)",
            padding: 0,
          }}
          onClick={onInfoClick}
        >
          Informazioni sull'host
        </p>
        <div
          style={{
            position: "absolute",
            width: "100%",
            top: scaleWidth(68),
            marginTop: scaleWidth(30),
            backgroundColor: "#fff",
          }}
        >
          {showInfo ? <LocalInformation local={local} readonly={true} /> : null}
        </div>
      </div>
    </div>
  );
};

export default LocalScreen;
