import { fontScale, scaleWidth } from "../utils/responsive";

import PropTypes from "prop-types";
import React from "react";
import colors from "../assets/styles/colors";

const Button = ({
  children,
  type = "primary",
  onClick,
  disabled = false,
  fontSize = fontScale(19),
  padding = {
    top: scaleWidth(12),
    bottom: scaleWidth(12),
    left: scaleWidth(25),
    right: scaleWidth(20),
  },
  borderRadius = scaleWidth(10),
  style = {}, // Allow custom styles to be passed in
}) => {
  const getBackgroundColor = (type) => {
    switch (type) {
      case "primary":
      case "success":
        return colors.primary;
      case "info":
      case "back":
        return colors.transparent;
      case "danger":
        return "#FF3B30";
      case "action":
        return "#222";
      default:
        return "#222";
    }
  };

  const getTextColor = (type) => {
    switch (type) {
      case "info":
        return "#707070";
      case "back":
        return "#222";
      default:
        return "#fff";
    }
  };

  const getTextDecoration = (type) => {
    return type === "info" || type === "back" ? "underline" : "none";
  };

  const getPadding = (type) => {
    if (type === "back") {
      return {
        top: scaleWidth(18),
        bottom: scaleWidth(10),
        left: scaleWidth(0),
        right: scaleWidth(0),
      };
    }
    return padding;
  };

  const commonStyles = {
    fontSize,
    fontWeight: "bold",
    fontFamily: "Lato, sans-serif",
    opacity: disabled ? 0.6 : 1,
    paddingLeft: getPadding(type).left,
    paddingRight: getPadding(type).right,
    paddingTop: getPadding(type).top,
    paddingBottom: getPadding(type).bottom,
    borderRadius,
    textDecoration: getTextDecoration(type),
    background: getBackgroundColor(type),
    color: getTextColor(type),
    ...style, // Merge custom style prop
  };

  return (
    <button
      style={commonStyles}
      onClick={disabled ? null : onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf([
    "primary",
    "secondary",
    "action",
    "info",
    "success",
    "danger",
    "submit",
    "back",
  ]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  fontSize: PropTypes.number,
  padding: PropTypes.shape({
    top: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number,
  }),
  borderRadius: PropTypes.number,
  style: PropTypes.object, // Allow custom styles to be passed
};

export default Button;
