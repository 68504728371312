import { fontScale, scaleWidth } from "../utils/responsive";
import PropTypes from "prop-types";
import React from "react";
import backIcon from "../assets/icons/back-orange.png";
import colors from "../assets/styles/colors";
import { useNavigate } from "react-router-dom";
import Button from "./Button"; // Assuming you have a Button component
import arrowLeftIcon from "../assets/icons/arrow-left.png";

const Header = ({
  title,
  hasBackButton = false,
  type = "primary",
  textAlign = "center",
  saveText,
  onSave,
  onBack,
}) => {
  const navigate = useNavigate();

  const defaultOnBack = () => {
    navigate(-1); // Navigate to the previous page
  };

  const styles = {
    spaceContainer: {
      height:
        type === "secondary" || type === "tertiary"
          ? scaleWidth(59)
          : scaleWidth(45),
    },
    headerContainer: {
      color:
        type === "secondary"
          ? colors.white
          : type === "tertiary"
          ? "#222"
          : colors.primary,
      backgroundColor: type === "secondary" ? "#ea802f" : colors.white,
      textAlign: textAlign,
      position: "fixed",
      width: "100%",
      zIndex: 100,
      top: 0,
      height:
        type === "secondary" || type === "tertiary"
          ? scaleWidth(59)
          : scaleWidth(45),
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      boxShadow:
        type !== "secondary" ? "0px 2px 4px rgba(0, 0, 0, 0.05)" : "none",
    },
    backIcon: {
      position: "absolute",
      left: scaleWidth(7),
      width: scaleWidth(34),
      height: scaleWidth(34),
      cursor: "pointer",
    },
    title: {
      margin: 0,
      fontSize:
        type === "secondary"
          ? fontScale(24)
          : type === "tertiary"
          ? fontScale(22)
          : fontScale(16),
      fontWeight: "bold",
    },
    saveButtonContainer: {
      position: "absolute",
      right: scaleWidth(7),
    },
  };

  return (
    <div style={styles.spaceContainer}>
      <div style={styles.headerContainer}>
        {hasBackButton && (
          <img
            src={type === "tertiary" ? arrowLeftIcon : backIcon}
            alt="Back"
            style={styles.backIcon}
            onClick={onBack || defaultOnBack}
          />
        )}
        <h1 style={styles.title}>{title}</h1>
        {saveText && onSave && (
          <div style={styles.saveButtonContainer}>
            <Button
              type="info"
              onClick={onSave}
              style={{
                color: colors.primary,
                fontSize: fontScale(20),
                marginRight: scaleWidth(7),
              }}
            >
              {saveText}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  hasBackButton: PropTypes.bool,
  type: PropTypes.oneOf(["primary", "secondary", "tertiary"]),
  textAlign: PropTypes.oneOf(["center", "left", "right"]),
  saveText: PropTypes.string, // Optional prop for save button text
  onSave: PropTypes.func, // Optional prop for save button handler
};

export default Header;
