import { fontScale, scaleWidth } from "../utils/responsive";
import React, { useState } from "react";
import Spacer from "./Spacer";
import dotsVertical from "../assets/icons/dots-vertical.png";
import colors from "../assets/styles/colors";

const LocalCard = ({ local, onClick, onDelete }) => {
  const [showOptions, setShowOptions] = useState(false);

  const toggleOptions = (e) => {
    e.stopPropagation(); // Prevent triggering the card's onClick
    setShowOptions(!showOptions);
  };

  const getType = (type) => {
    switch (type) {
      case "bar":
        return "Bar";
      case "beachclub":
        return "Beach Club";
      case "caffe":
        return "Caffè";
      case "disco":
        return "Discoteca";
      case "wineshop":
        return "Enoteca";
      case "fastfood":
        return "Fast Food";
      case "icecream":
        return "Gelateria";
      case "lounge":
        return "Lounge Bar";
      case "nightclub":
        return "Night Club";
      default:
        return "Altro";
    }
  };

  const extractCityAndProvince = (address) => {
    const regex = /,\s*\d*\s*([^,]+?)\s+([A-Z]{2}),\s*Italia$/;
    const match = address.match(regex);

    if (match) {
      const city = match[1].trim();
      const province = match[2].trim();
      return `${city} (${province})`;
    } else {
      return "Errore nell'indirizzo";
    }
  };

  return (
    <div
      style={{
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
        borderRadius: "10px",
        width: scaleWidth(342),
        height: scaleWidth(101),
        marginLeft: scaleWidth(20),
        borderTopLeftRadius: "100px",
        borderBottomLeftRadius: "100px",
        marginBottom: scaleWidth(20),
      }}
      onClick={onClick}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <div>
          <img
            style={{
              width: scaleWidth(86),
              height: scaleWidth(86),
              borderRadius: "50%",
              marginTop: scaleWidth(7),
              marginLeft: scaleWidth(8),
              marginRight: scaleWidth(17),
              backgroundColor: "#f6f6f6",
            }}
            src={process.env.REACT_APP_BASE_MEDIA_URL + local.logoUrl}
            alt="Logo"
          />
        </div>

        <div
          style={{
            height: scaleWidth(101),
            width: "100%",
            textAlign: "left",
          }}
        >
          <Spacer height={12} />
          <p
            style={{
              fontSize: fontScale(18.5),
              fontWeight: "bold",
            }}
          >
            {local.name ?? ""}
          </p>
          <Spacer height={5} />
          <p
            style={{
              fontSize: fontScale(18),
              color: colors.primary,
            }}
          >
            {getType(local.type) ?? ""}
          </p>
          <p
            style={{
              fontSize: fontScale(18),
              color: "#555",
            }}
          >
            {extractCityAndProvince(local.address) ?? ""}
          </p>
        </div>

        <div
          onClick={toggleOptions}
          style={{
            position: "relative",
            width: scaleWidth(40),
          }}
        >
          <img
            src={dotsVertical}
            style={{
              width: scaleWidth(3.2),
              marginRight: scaleWidth(15),
            }}
            alt="Options"
          />
          {showOptions && (
            <div
              style={{
                position: "absolute",
                top: scaleWidth(20),
                right: 0,
                backgroundColor: "#fff",
                border: "1px solid #ccc",
                borderRadius: "4px",
                padding: "8px",
                boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                zIndex: 1000,
              }}
            >
              <p
                style={{ margin: 0, cursor: "pointer" }}
                onClick={(e) => {
                  e.stopPropagation(); // Prevents closing the card
                  onDelete(local.id);
                  setShowOptions(false);
                }}
              >
                Elimina
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LocalCard;
