/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { fontScale, scaleWidth } from "../../utils/responsive";

import BottomNavBar from "../../components/BottomNavBar";
import Header from "../../components/Header";
import Input from "../../components/Input";
import Spacer from "../../components/Spacer";
import authService from "../../services/authService";
import colors from "../../assets/styles/colors";
import { createItem } from "../../services/apiService";
import { useLocation } from "react-router-dom";

import checkIcon from "../../assets/icons/check.png";
import checkGreenIcon from "../../assets/icons/check-green.png";

const SignupScreen = () => {
  const location = useLocation();

  // Retrieve form data passed from the previous screen
  const {
    accountType = "",
    company = "",
    vat = "",
    admin = "",
    phone = "",
    displayName = "",
  } = location.state || {};

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({ email: "", password: "" });
  const [passwordValidations, setPasswordValidations] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    specialChar: false,
    number: false,
  });
  const [checkboxChecked, setCheckboxChecked] = useState(false); // New state for checkbox
  const [disableNext, setDisableNext] = useState(true); // State to control the BottomNavBar button disable

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    return {
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      specialChar: /\W/.test(password),
      number: /\d/.test(password),
    };
  };

  useEffect(() => {
    const validations = validatePassword(password);
    setPasswordValidations(validations);

    const isEmailValid = validateEmail(email);
    const isPasswordValid = Object.values(validations).every(Boolean);

    // Disable the "Next" button if either email, password is invalid, or checkbox is unchecked
    setDisableNext(!(isEmailValid && isPasswordValid && checkboxChecked));
  }, [email, password, checkboxChecked]);

  const handleSubmit = async () => {
    let valid = true;
    let newErrors = { email: "", password: "" };

    if (!validateEmail(email)) {
      valid = false;
      newErrors.email = "L'indirizzo email non è valido.";
    }

    const validations = validatePassword(password);
    if (!Object.values(validations).every(Boolean)) {
      valid = false;
      newErrors.password =
        "La password deve avere almeno 8 caratteri, una lettera maiuscola, una lettera minuscola, un numero e un carattere speciale.";
    }

    setErrors(newErrors);

    if (valid) {
      try {
        const { user } = await authService.signUpWithEmail(email, password);

        // Prepare data for the "hosts" collection
        const payload = {
          firebaseUid: user.uid,
          email,
          role: accountType, // role is passed from the previous screen
          name: company, // company name passed from previous screen
          vat, // vat passed from previous screen
          administrator: admin, // admin name passed from previous screen
          phone, // phone number passed from previous screen
          displayName,
        };

        // Call the backend API to save the host data
        await createItem("hosts", payload);
      } catch (error) {
        console.error("Error during Firebase account creation:", error);
        alert("There was an error creating your account. Please try again.");
      }
    }
  };

  return (
    <>
      <Header title="CREA IL TUO ACCOUNT 3-3" />
      <div
        style={{ paddingRight: scaleWidth(28), paddingLeft: scaleWidth(28) }}
      >
        <Spacer height={20} />
        <h1
          style={{
            color: colors.black,
            textAlign: "left",
            fontSize: scaleWidth(30),
            lineHeight: "118%",
            fontWeight: 900,
          }}
        >
          Imposta le credenziali per registrare il tuo Account Manager
        </h1>

        <Spacer height={40} />
        <Input
          label={"Indirizzo email"}
          placeholder="Inserisci indirizzo email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {errors.email && (
          <p style={{ color: "red", fontSize: fontScale(14) }}>
            {errors.email}
          </p>
        )}
        <Spacer height={30} />
        <Input
          label={"Password"}
          placeholder="Inserisci Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {errors.password && (
          <p style={{ color: "red", fontSize: fontScale(14) }}>
            {errors.password}
          </p>
        )}
        <Spacer height={10} />
        <p
          style={{
            fontSize: fontScale(15),
            color: "#555",
            fontWeight: 300,
            marginLeft: scaleWidth(10),
          }}
        >
          <span>
            <span
              style={{
                color: passwordValidations.length ? "#3a993e" : "#555",
              }}
            >
              *almeno 8 caratteri{" "}
              {passwordValidations.length && (
                <img
                  src={checkGreenIcon}
                  style={{
                    width: scaleWidth(11),
                  }}
                  alt="Check"
                />
              )}
            </span>
            <br />
            <span
              style={{
                color: passwordValidations.uppercase ? "#3a993e" : "#555",
              }}
            >
              *almeno una lettera maiuscola{" "}
              {passwordValidations.uppercase && (
                <img
                  src={checkGreenIcon}
                  style={{
                    width: scaleWidth(11),
                  }}
                  alt="Check"
                />
              )}
            </span>
            <br />
            <span
              style={{
                color: passwordValidations.lowercase ? "#3a993e" : "#555",
              }}
            >
              *almeno una lettera minuscola{" "}
              {passwordValidations.lowercase && (
                <img
                  src={checkGreenIcon}
                  style={{
                    width: scaleWidth(11),
                  }}
                  alt="Check"
                />
              )}
            </span>
            <br />
            <span
              style={{
                color: passwordValidations.specialChar ? "#3a993e" : "#555",
              }}
            >
              *almeno un carattere speciale{" "}
              {passwordValidations.specialChar && (
                <img
                  src={checkGreenIcon}
                  style={{
                    width: scaleWidth(11),
                  }}
                  alt="Check"
                />
              )}
            </span>
            <br />
            <span
              style={{
                color: passwordValidations.number ? "#3a993e" : "#555",
              }}
            >
              *almeno un numero{" "}
              {passwordValidations.number && (
                <img
                  src={checkGreenIcon}
                  style={{
                    width: scaleWidth(11),
                  }}
                  alt="Check"
                />
              )}
            </span>
          </span>
        </p>

        <Spacer height={58} />

        {/* Add custom checkbox for terms of service */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingLeft: scaleWidth(19),
            paddingRight: scaleWidth(19),
          }}
        >
          <CustomCheckbox
            checked={checkboxChecked}
            setChecked={setCheckboxChecked}
          />
          <p style={{ marginLeft: scaleWidth(14), fontSize: fontScale(15) }}>
            Accetto i{" "}
            <a href="#" style={{ color: "#ea802f" }}>
              Termini di servizio
            </a>{" "}
            e autorizzo il trattamento dei miei dati personali in conformità con
            la{" "}
            <a href="#" style={{ color: "#ea802f" }}>
              Privacy Policy
            </a>
            .
          </p>
        </div>
      </div>
      <Spacer height={120} />
      <BottomNavBar
        onNext={handleSubmit}
        showBackButton={true}
        nextLabel={"Registra Account"}
        showArrow={true}
        disableNext={disableNext} // Disable the next button if email, password, or checkbox is invalid
      />
    </>
  );
};

// CustomCheckbox component
const CustomCheckbox = ({ checked, setChecked }) => {
  const toggleCheckbox = () => setChecked(!checked);

  return (
    <div
      onClick={toggleCheckbox}
      style={{
        width: scaleWidth(24),
        minWidth: scaleWidth(24),
        height: scaleWidth(24),
        minHeight: scaleWidth(24),
        border: checked ? "0px" : "1px solid #e2e2e2",
        borderRadius: 5,
        backgroundColor: checked ? "#ea802f" : "#fff",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
      }}
    >
      {checked && (
        <span style={{ color: "white" }}>
          <img
            src={checkIcon}
            style={{ width: scaleWidth(12), height: scaleWidth(9) }}
            alt="Check"
          />
        </span>
      )}
    </div>
  );
};

export default SignupScreen;
