import React, { useEffect, useState } from "react";
import { fontScale, scaleWidth } from "../../../utils/responsive";
import { useLocation, useNavigate } from "react-router-dom";

import { FaUpload } from "react-icons/fa6";
import Header from "../../../components/Header";
import Label from "../../../components/Label";
import Spacer from "../../../components/Spacer";
import axios from "axios";
import categories from "../../../utils/categories";
import BottomNavBar from "../../../components/BottomNavBar";
import colors from "../../../assets/styles/colors";
import checkIcon from "../../../assets/icons/check.png";

const Divider = () => (
  <hr
    style={{
      width: scaleWidth(390),
      marginLeft: scaleWidth(-35),
      border: "none",
      borderTop: "1px solid #efefef",
    }}
  />
);

const CustomCheckbox = ({ checked, setChecked }) => {
  const toggleCheckbox = () => setChecked(!checked);

  return (
    <div
      onClick={toggleCheckbox}
      style={{
        width: scaleWidth(24),
        minWidth: scaleWidth(24),
        height: scaleWidth(24),
        minHeight: scaleWidth(24),
        border: checked ? "0px" : "1px solid #e2e2e2",
        borderRadius: 5,
        backgroundColor: checked ? "#ea802f" : "#fff",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
      }}
    >
      {checked && (
        <span style={{ color: "white" }}>
          <img
            src={checkIcon}
            style={{ width: scaleWidth(12), height: scaleWidth(9) }}
            alt="Check"
          />
        </span>
      )}
    </div>
  );
};

const AddEventMoreScreen = ({
  mode = "create",
  editData,
  setEditedFormData,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  // const eventData = location.state?.eventData || {};
  const initialFormData = location.state?.eventData || {};

  console.log("Initial form data:", initialFormData.description);

  const [formData, setFormData] = useState({
    imgUrl: initialFormData.imgUrl || "",
    description: initialFormData.description || "",
    category: "",
    categories: [], // New state for multiple categories
    tags: [],
    hasEasyAccess: initialFormData.hasEasyAccess || false,
    isOutdoor: initialFormData.isOutdoor || false,
    isIndoor: initialFormData.isIndoor || false,
    isForKids: initialFormData.isForKids || false,
    allowAnimals: initialFormData.allowAnimals || false,
    onlyAdults: initialFormData.onlyAdults || false,
    ...initialFormData,
  });

  useEffect(() => {
    if (mode === "edit" && editData) {
      console.log("Edit data:", editData.hasEasyAccess);
      setFormData((prev) => ({
        ...prev,
        ...editData,
      }));
    }
  }, [mode]);

  useEffect(() => {
    if (setEditedFormData) {
      setEditedFormData({ ...formData });
    }
  }, [formData]);

  const handleCategoryClick = (category) => {
    setFormData((prevState) => {
      const isSelected = prevState.categories.includes(category);
      const newCategories = isSelected
        ? prevState.categories.filter((cat) => cat !== category)
        : prevState.categories.length < 2
        ? [...prevState.categories, category]
        : prevState.categories;
      return { ...prevState, categories: newCategories };
    });
  };

  const handleTagClick = (tag) => {
    setFormData((prevState) => {
      const isSelected = prevState.tags.includes(tag);
      const newTags = isSelected
        ? prevState.tags.filter((t) => t !== tag) // Remove the tag if it's already selected
        : [...prevState.tags, tag]; // Add the tag if it's not selected
      return { ...prevState, tags: newTags };
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "category") {
      setFormData({ ...formData, category: value, subcategory: "" });
    }
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("img", file);

    try {
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + "/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setFormData((prevState) => ({
        ...prevState,
        imgUrl: `/media/${response.data.filename}`, // Save the image URL
      }));
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Final data: #1", formData.description);

    // Prepare the final formData with any adjustments needed
    const finalData = {
      ...formData,
      category: formData.categories[0] || "", // Set `category` for compatibility
    };

    console.log("Final data:", finalData.description, formData.description);

    // Navigate to the preview screen with the formData
    navigate("/dashboard/event/preview", {
      state: { event: finalData, isNew: true },
    });
  };

  const handleBack = () => {
    navigate("/dashboard/event/add", { state: { formData } });
  };

  return (
    <div>
      {mode === "create" && (
        <Header
          title={"CREA NUOVO EVENTO 2/2"}
          hasBackButton={true}
          onBack={handleBack}
        />
      )}

      <div
        style={{
          paddingRight: scaleWidth(28),
          paddingLeft: scaleWidth(28),
          paddingTop: scaleWidth(20),
        }}
      >
        {mode === "create" && (
          <>
            <h2>Aggiungi i dettagli finali</h2>

            <Spacer height={10} />
            <p style={{ fontSize: fontScale(20) }}>
              Le informazioni che inserirai saranno fondamentali per indicizzare
              il tuo locale all’interno della piattaforma.
            </p>
          </>
        )}

        <Spacer height={40} />

        <div>
          <Spacer height={20} />
          <Label text="Locandina dell'evento" />
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                position: "relative",
                width: scaleWidth(165),
                height: scaleWidth(233),
                borderRadius: 10,
                border: "1px solid #ccc",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                backgroundImage: formData.imgUrl
                  ? `url(${
                      process.env.REACT_APP_BASE_MEDIA_URL + formData.imgUrl
                    })`
                  : "none",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
              onClick={() => document.getElementById("fileInput").click()}
            >
              {!formData.imgUrl && <FaUpload size={50} color="#ccc" />}
              <input
                id="fileInput"
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                style={{
                  display: "none",
                }}
              />
            </div>
            <div
              style={{
                width: scaleWidth(150),
              }}
            >
              <Spacer height={40} />

              <p
                style={{
                  fontSize: fontScale(15),
                  lineHeight: fontScale(1.2),
                  marginLeft: scaleWidth(10),
                  fontWeight: "300",
                }}
              >
                <i>Formato minimo A5: 420px x 595px</i>
              </p>

              <Spacer height={20} />
              <p
                style={{
                  fontSize: fontScale(15),
                  lineHeight: fontScale(1.2),
                  marginLeft: scaleWidth(10),
                  fontWeight: "300",
                }}
              >
                <i>Dimensione massima: 1 MB</i>
              </p>
            </div>
          </div>

          <Spacer height={40} />
          <Divider />
          <Spacer height={30} />

          <Label text="Descrizione/Informazioni sull'evento" />
          <textarea
            name="description"
            placeholder='Benvenuti nel locale..."'
            value={formData.description}
            onChange={handleChange}
            style={{
              color: formData.description ? "#222" : "#D0D0D0",
              fontSize: fontScale(20),
              borderColor: "#D0D0D0",
              opacity: 1,
              borderWidth: 0.5,
              borderRadius: 6,
              padding: scaleWidth(15),
              height: scaleWidth(128),
              width: "90%",
              borderStyle: "solid", // Ensure the border style is solid
              fontFamily: "Lato",
            }}
          />

          <Spacer height={40} />
          <Divider />
          <Spacer height={30} />

          <Label text="Seleziona Categorie e Tag" />

          <Spacer height={20} />

          <div
            style={{ display: "flex", flexWrap: "wrap", gap: scaleWidth(10) }}
          >
            {Object.keys(categories).map((category) => (
              <div
                key={category}
                onClick={() => handleCategoryClick(category)}
                style={{
                  borderRadius: scaleWidth(12),
                  backgroundColor: "#fff",
                  border: formData.categories.includes(category)
                    ? "2px solid #eb7c27"
                    : "0px solid #ddd",
                  color: formData.categories.includes(category)
                    ? colors.primary
                    : "#222",
                  fontSize: fontScale(13),
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: scaleWidth(95),
                  height: scaleWidth(95),
                  textAlign: "center",
                  fontWeight: "700",
                  boxShadow: "0px 0px 5px 1px rgba(0, 0, 0, 0.05)",
                }}
              >
                {category}
              </div>
            ))}
          </div>
          <Spacer height={20} />
          {formData.categories.length > 0 && (
            <>
              <Label text="Seleziona Tag" />
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: scaleWidth(8),
                }}
              >
                {formData.categories.flatMap((category) =>
                  categories[category].map((tag) => (
                    <div
                      key={tag}
                      onClick={() => handleTagClick(tag)}
                      style={{
                        height: scaleWidth(42),
                        paddingLeft: scaleWidth(20),
                        paddingRight: scaleWidth(20),
                        borderRadius: scaleWidth(25),
                        backgroundColor: "#fff",
                        color: formData.tags.includes(tag)
                          ? "#eb7c27"
                          : "#b2b2b2",
                        fontSize: fontScale(18),
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        border: formData.tags.includes(tag)
                          ? "1.5px solid #eb7c27"
                          : "1.5px solid #e8e8e8",
                      }}
                    >
                      {tag}
                    </div>
                  ))
                )}
              </div>
            </>
          )}
          {formData.tags.length > 0 && (
            <>
              <Spacer height={30} />
              <Label text="Tags Selezionati" />
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: scaleWidth(10),
                }}
              >
                {formData.tags.map((tag) => (
                  <div
                    key={tag}
                    onClick={() => handleTagClick(tag)} // Call handleTagClick to remove the tag
                    style={{
                      padding: scaleWidth(10),
                      borderRadius: scaleWidth(29),
                      backgroundColor: "#fff",
                      color: "#eb7c27",
                      fontSize: fontScale(16),
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      border: "0.5px solid #eb7c27",
                      cursor: "pointer", // Change cursor to pointer to indicate clickability
                    }}
                  >
                    {tag}
                  </div>
                ))}
              </div>
            </>
          )}

          <Spacer height={40} />
          <Divider />
          <Spacer height={30} />

          <Label text="Seleziona ulteriori pecularità sull'evento che stai pubblicando" />
          <Spacer height={30} />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              height: scaleWidth(50),
            }}
          >
            <Label text="All'aperto" />

            <CustomCheckbox
              checked={formData.isOutdoor}
              setChecked={(value) =>
                setFormData({ ...formData, isOutdoor: value })
              }
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              height: scaleWidth(50),
            }}
          >
            <Label text="Al chiuso" />

            <CustomCheckbox
              checked={formData.isIndoor}
              setChecked={(value) =>
                setFormData({ ...formData, isIndoor: value })
              }
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              height: scaleWidth(50),
            }}
          >
            <Label text="Adatto ai bambini" />

            <CustomCheckbox
              checked={formData.isForKids}
              setChecked={(value) =>
                setFormData({ ...formData, isForKids: value })
              }
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              height: scaleWidth(50),
            }}
          >
            <Label text="Accessibilità facilitata" />

            <CustomCheckbox
              checked={formData.hasEasyAccess}
              setChecked={(value) =>
                setFormData({ ...formData, hasEasyAccess: value })
              }
            />
          </div>
          <i
            style={{
              fontSize: fontScale(17),
              lineHeight: fontScale(1.4),
              color: "#555",
              marginLeft: scaleWidth(5),
              display: "block",
              width: scaleWidth(272),
            }}
          >
            L’evento che stai creando permette alle persone con disabilità di
            accedere con praticità e in totale sicurezza
          </i>
          <Spacer height={20} />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              height: scaleWidth(50),
            }}
          >
            <Label text="Vietato ai minori (+18)" />

            <CustomCheckbox
              checked={formData.onlyAdults}
              setChecked={(value) =>
                setFormData({ ...formData, onlyAdults: value })
              }
            />
          </div>
          <i
            style={{
              fontSize: fontScale(17),
              lineHeight: fontScale(1.4),
              color: "#555",
              marginLeft: scaleWidth(5),
              display: "block",
              width: scaleWidth(272),
            }}
          >
            L’evento che stai creando può ospitare solamente maggiorenni
          </i>
          <Spacer height={20} />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              height: scaleWidth(50),
            }}
          >
            <Label text="Animali ammessi" />

            <CustomCheckbox
              checked={formData.allowAnimals}
              setChecked={(value) =>
                setFormData({ ...formData, allowAnimals: value })
              }
            />
          </div>
          <i
            style={{
              fontSize: fontScale(17),
              lineHeight: fontScale(1.4),
              color: "#555",
              marginLeft: scaleWidth(5),
              display: "block",
              width: scaleWidth(272),
            }}
          >
            L’evento che stai creando può ospitare animali all’interno
          </i>
          <Spacer height={20} />

          <Spacer height={150} />
          {mode === "create" && (
            <BottomNavBar
              onBack={handleBack}
              onNext={handleSubmit}
              showBackButton={true}
              nextLabel={"Vedi anteprima"}
              showArrow={true}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AddEventMoreScreen;
